import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import { connect } from "react-redux";
import { updateProfile } from "../../actions/authActions";
import axios from "axios";
import apiUrl from "../../config/config";

import TopNav from "../../components/common/TopNav";
import SideBar from "../../components/common/SideBar";

class AdminProfile extends Component {
  constructor() {
    super();
    this.state = {
      menus: [{ title: "Patient List", url: "/patient-list" }],
      menu: [{ title: "patient List", url: "/Partner" }],

      profileLink: "verifier-profile",
      name: "",
      phone: "",
      email: "",
      gender: "M",
      address: "",
      errors: {},
    };
  }

  componentDidMount() {
    axios
      .get(apiUrl + "api/users/profile")
      .then((res) => {
        console.log(res.data);
        this.setState({
          name: res.data.name,
          phone: res.data.phone,
          email: res.data.email,
          gender: res.data.gender,
          address: res.data.address,
          id: res.data._id,
        });
      })
      .catch((err) => {});
  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps);
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onChange = (e) => {
    var err = this.state.errors;
    delete err[e.target.name];
    console.log(e.target.value);
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();

    const user = {
      id: this.state.id,
      name: this.state.name,
      phone: this.state.phone,
      email: this.state.email,
      gender: this.state.gender,
      address: this.state.address,
    };
    this.props.updateProfile(user, this.props.history);
  };

  render() {
    const { errors, patients } = this.state;

    return (
      <div className="wrapper theme-1-active box-layout pimary-color-green">
        <TopNav profileLink={this.state.profileLink} />
        <SideBar
          menus={
            localStorage.getItem("verifiertype") !== "7"
              ? this.state.menus
              : this.state.menu
          }
        />

        <div className="right-sidebar-backdrop"></div>
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row heading-bg">
              <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h5 className="txt-dark">Profile</h5>
              </div>
              <div className="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <ol className="breadcrumb">
                  <li>
                    <a href="index.html">Home </a>
                  </li>
                  <li>
                    <a href="#">
                      <span>Profile</span>
                    </a>
                  </li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div className="panel panel-default card-view">
                  <div className="panel-wrapper collapse in">
                    <div className="panel-body">
                      <div className="panel-wrapper">
                        <div className="panel-body">
                          <div className="form-wrap mt-40">
                            <form
                              name="profile_form"
                              action=""
                              id="profile_form"
                              method="POST"
                              onSubmit={this.onSubmit}
                            >
                              <div className="row">
                                <div className="col-md-6 col-sm-12">
                                  <div className="form-group">
                                    <label className="control-label mb-10">
                                      Name
                                    </label>
                                    <input
                                      type="text"
                                      className={classnames("form-control", {
                                        errors: errors.name,
                                      })}
                                      name="name"
                                      required=""
                                      id="name"
                                      onChange={this.onChange}
                                      value={this.state.name}
                                      placeholder="Username"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                  <div className="form-group">
                                    <label className="control-label mb-10 text-left">
                                      Email
                                    </label>
                                    <input
                                      type="text"
                                      className={classnames("form-control", {
                                        errors: errors.email,
                                      })}
                                      name="email"
                                      required=""
                                      id="email"
                                      onChange={this.onChange}
                                      value={this.state.email}
                                      placeholder="Enter email"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                  <div className="form-group">
                                    <label className="control-label mb-10 text-left">
                                      Mobile Number
                                    </label>
                                    <input
                                      type="number"
                                      className={classnames("form-control", {
                                        errors: errors.phone,
                                      })}
                                      name="phone"
                                      required=""
                                      id="phone"
                                      onChange={this.onChange}
                                      value={this.state.phone}
                                      placeholder="Phone"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                  <div className="form-group">
                                    <label className="control-label mb-10">
                                      Address
                                    </label>
                                    <textarea
                                      className="form-control"
                                      name="address"
                                      id="address"
                                      onChange={this.onChange}
                                      value={this.state.address}
                                    ></textarea>
                                  </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                  <div className="form-group mb-30">
                                    <label className="control-label mb-10 text-left">
                                      Gender
                                    </label>
                                    <div className="radio radio-primary">
                                      <input
                                        type="radio"
                                        name="gender"
                                        id="radio1"
                                        value="M"
                                        checked={this.state.gender === "M"}
                                        onChange={this.onChange}
                                      />
                                      <label htmlFor="radio1">M</label>
                                    </div>
                                    <div className="radio radio-primary">
                                      <input
                                        type="radio"
                                        name="gender"
                                        id="radio2"
                                        value="F"
                                        checked={this.state.gender === "F"}
                                        onChange={this.onChange}
                                      />
                                      <label htmlFor="radio2">F</label>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                  <div className="form-group mb-0">
                                    <div className="   col-sm-10">
                                      <button
                                        type="submit"
                                        className="btn btn-success"
                                        id="profile_add"
                                      >
                                        <span className="btn-text">Update</span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AdminProfile.propTypes = {
  updateProfile: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { updateProfile })(
  withRouter(AdminProfile)
);
