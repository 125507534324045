import React, {
    Component
} from 'react';
import classnames from 'classnames';
import axios from 'axios';
import apiUrl from '../../config/config';
import $ from "jquery";
import {
    connect
} from 'react-redux';
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import PropTypes from 'prop-types';
import TopNav from '../../components/common/TopNav';
import VerifierSummary from '../verifier/VerifierSummary';
import SettingsTab from '../../components/graph/SettingsTab';
import HeartRateTab from '../graph/HeartRateTab';
import SideBarGraph from '../graph/SideBarGraph';
import AddDoctorFindingsTab from '../../components/graph/AddDoctorFindingsTab';
import DiaryNotes from '../../components/graph/DiaryNotes';
import VerifierSelectedBeats from '../verifier/VerifierSelectedBeats';
// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from 'worker-loader!../verifier/worker.js';
import AddModal from '../verifier/AddModal';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Loader from 'react-loader-spinner';

const deviceFrequency = 15000;
let worker,worker2,worker3,worker7,worker13,worker14,worker15,worker6;
let graphColor = '';
let gainMin = -0.0005;
let gainMax = 0.0015;
let clickContainerIndex = 0;
let chartCount = 0;
let pageIndex = 0;
let strip_val;
window.tab = 'Beats';
window.prev_tab = 'Events';
require('highcharts-multicolor-series')(Highcharts);

class VerifierChart extends Component {

    constructor() {
        super();
        /********* State Variables*************/
        worker = new Worker();
        worker2 = new Worker();
        worker3 = new Worker();
        worker6 = new Worker();
        worker7 = new Worker();
        worker13 = new Worker();
        worker14 = new Worker();
        worker15 = new Worker();
       
        this.state = {
            menus: [{
                title: 'Patient List',
                url: '/doctor-home'
            }, ],
            profileLink: '/verifier-profile',
            clickdb:'',
            treemenu: [],
            clickedPI: '', //used in updateState()
            rpeaks: [], //For Summar Tab
            databaseid: '',
            reoprtLink: '',
            reportLinkStatus: 0,
            reportStatus: '',
            images: {},
            imagess: {},
          
            datasets:{},
            dblink: '', // check whether it is same as the previous databaseid
            nextDbId: '',
            prevDbId: '',
            fullVerifyStatus: 100,
            chart: '',
            isModalOpen:false,
            result3: [],
            charts: [],
            stripcount:1,
            labelinfo:'Yes',
            sel_arr:'CURRENT',
            arr_list:'CURRENT',
            newName:'CURRENT',
            starr: [],
            errors: {},
            defarr: [],
            selectedChart: 0, //Check Whether it is needed
            mainEcgArray: [],
            mainActivityArray: [],
            datasetMnt: 0, //Check whether we need a state variable for this
            currentTab: 'chart_tab_2',
            start: 0,
            end: 9,
            limitScrollStart: 1,
            limitScrollEnd: 1,
            peakStart: 0,
            graphMarker: {},
            patientMenuTree: {},
            verifyCheck: '',
            patientReportStatus: '',
            scrollStatus: '', //Remove this variable's usages then delete this
            summaryData: '',
            keyIntegrationStatus: 0
        };

        /********* State Variables*************/
       // this.handleModal=this.handleModal.bind(this);
        this.handleDiary=this.handleDiary.bind(this);
        this.updateState = this.updateState.bind(this); 

       // this.selectImage = this.selectImage.bind(this);
        this.updateRpeaks = this.updateRpeaks.bind(this); //saves peakvalues to state variable
        this.generateReport = this.generateReport.bind(this); //Executes on Generate Report Button
     //   this.mailReport = this.mailReport.bind(this); //Mails Report
        this.newDataClick = this.newDataClick.bind(this); //For navigation to Next/Previous database.Currently displayed None
        this.updateMainChartODC = this.updateMainChartODC.bind(this); //Exeutes On Double click on Event Image within updateState()
    //    this.recategorizeAfterImage = this.recategorizeAfterImage.bind(this);
        
        this.enableKey=this.enableKey.bind(this);
    }

    setTab(e) {
        var currentTab = e.currentTarget.id;
        this.setState({
            currentTab: currentTab
        });
        console.log('chart tab!!!!!!!',currentTab)


         if(e.currentTarget.id=="chart_tab_2" && this.props.match.params.start==undefined)
        {
             document.getElementById("chart_tab_2").innerHTML="<img id=\"beatsimg\" src=\"../../dist/img/BeatsSelect.png\"/>";
              document.getElementById("chart_tab_findings").innerHTML="<img id=\"addFindImg\" src=\"../../dist/img/AddFindings.png\"/>";
             
        }
         if(e.currentTarget.id=="chart_tab_2" && this.props.match.params.start!=undefined)
        {
             document.getElementById("chart_tab_2").innerHTML="<img id=\"beatsimg\" src=\"../../../../../dist/img/BeatsSelect.png\"/>";
              document.getElementById("chart_tab_findings").innerHTML="<img id=\"addFindImg\" src=\"../../../../../dist/img/AddFindings.png\"/>";
             
        }
         else if(e.currentTarget.id=="chart_tab_1"&& this.props.match.params.start==undefined)
        {
             document.getElementById("chart_tab_2").innerHTML="<img id=\"beatsimg\" src=\"../../dist/img/Beats.png\"/>";
              document.getElementById("chart_tab_findings").innerHTML="<img id=\"addFindImg\" src=\"../../dist/img/AddFindings.png\"/>";
             
        }
        else if(e.currentTarget.id=="chart_tab_1"&& this.props.match.params.start!=undefined)
        {
             document.getElementById("chart_tab_2").innerHTML="<img id=\"beatsimg\" src=\"../../../../../dist/img/Beats.png\"/>";
              document.getElementById("chart_tab_findings").innerHTML="<img id=\"addFindImg\" src=\"../../../../../dist/img/AddFindings.png\"/>";      
        }
         
         else if(e.currentTarget.id=="chart_tab_4"&& this.props.match.params.start==undefined)
        {
             document.getElementById("chart_tab_2").innerHTML="<img id=\"beatsimg\" src=\"../../dist/img/Beats.png\"/>";
              document.getElementById("chart_tab_findings").innerHTML="<img id=\"addFindImg\" src=\"../../dist/img/AddFindings.png\"/>";
             
        }
         else if(e.currentTarget.id=="chart_tab_4"&& this.props.match.params.start!=undefined)
        {
             document.getElementById("chart_tab_2").innerHTML="<img id=\"beatsimg\" src=\"../../../../../dist/img/Beats.png\"/>";
              document.getElementById("chart_tab_findings").innerHTML="<img id=\"addFindImg\" src=\"../../../../../dist/img/AddFindings.png\"/>";
             
        }
         else if(e.currentTarget.id=="chart_tab_findings" && this.props.match.params.start==undefined)
        {
             document.getElementById("chart_tab_2").innerHTML="<img id=\"beatsimg\" src=\"../../dist/img/Beats.png\"/>";
              document.getElementById("chart_tab_findings").innerHTML="<img id=\"addFindImg\" src=\"../../dist/img/AddFindings_white.png\"/>";
             
        }
        else if(e.currentTarget.id=="chart_tab_findings" && this.props.match.params.start!=undefined)
        {
             document.getElementById("chart_tab_2").innerHTML="<img id=\"beatsimg\" src=\"../../../../../dist/img/Beats.png\"/>";
              document.getElementById("chart_tab_findings").innerHTML="<img id=\"addFindImg\" src=\"../../../../../dist/img/AddFindings_white.png\"/>";
             
        }
        else if(e.currentTarget.id=="chart_tab_8" && this.props.match.params.start==undefined)
        {
             document.getElementById("chart_tab_2").innerHTML="<img id=\"beatsimg\" src=\"../../dist/img/Beats.png\"/>";
              document.getElementById("chart_tab_findings").innerHTML="<img id=\"addFindImg\" src=\"../../dist/img/AddFindings.png\"/>";
             
        }
        else if(e.currentTarget.id=="chart_tab_8" && this.props.match.params.start!=undefined)
        {
             document.getElementById("chart_tab_2").innerHTML="<img id=\"beatsimg\" src=\"../../../../../dist/img/Beats.png\"/>";
              document.getElementById("chart_tab_findings").innerHTML="<img id=\"addFindImg\" src=\"../../../../../dist/img/AddFindings.png\"/>";   
        }
        window.prev_tab=window.tab;
        window.tab=e.currentTarget.innerHTML;   
    }


    updateState(e) {
         window.prev_tab=window.tab;
        window.tab = 'Events';
        var clickedPI = e.currentTarget.id.toString();
        var clickedPI_ = clickedPI.substring(0, 2) + ':' + clickedPI.substring(2, 4) + ':' + clickedPI. substring(4, 6);
        var userid = this.props.match.params.userid;
        var databaseid = this.props.match.params.databaseid;
        var usSelf = this;

        if (typeof databaseid === 'undefined') {
            databaseid = "0";
        }
        const data = {
            userid: userid,
            databaseid: databaseid,
            clickedPI_: clickedPI_
        };
        console.log('clickedPI_', clickedPI_);
        axios
            .post(apiUrl + 'api/common/getRpeakIndex', data)
            .then(response => {
                var clickedPI = response.data.rpeakindex;
                document.getElementById('chart_tab_2').click();
                if (clickedPI > 15000) {
                    var stripIndex = Math.floor(clickedPI / 15000);
                } else {
                    var stripIndex = 0;
                }
                window.chartid = 0;

                window.stripXValue = parseInt(clickedPI) - (stripIndex * 15000);
                var start = stripIndex,
                    end = stripIndex + 9;
                    console.log('starts', start);
                    console.log('prevStart',window.prevStart);
                     window.container=start;
                usSelf.setState({
                    start: start,
                    end: end
                });
                setTimeout(function() {
                    if(window.prevStart<=start)
                    {
                        if($('#selWrap').position().top -  $('#container' + start).position().top   !=0)
                        {      $('#selWrap').scrollTop(0);
                              $('#selWrap').scrollTop(  $('#container' + start).position().top -$('#selWrap').position().top );
                       
                        window.prevStart=start;
                         }
                    }
                    else if(window.prevStart>start)
                    {
                          $('#selWrap').scrollTop(0);
                         $('#selWrap').scrollTop(  $('#container' + start).position().top -$('#selWrap').position().top );
                          window.prevStart=start;
                    }
                }, 1000);
            })
            .catch(err =>
                console.log(err)
            );
    }
  
    updateMainChartODC(starts, ends) {
        var userid = userid;
        var mainEcgArray, mainActivityArray = [];
        var result, result2, result3, colorArray = [];
        var chart;

        var tonarr = [],
        rarr = [];
   
        var peakColorArray = [];
        peakColorArray['Normal Sinus Rhythm'] = '#09ff08';
        peakColorArray['Sinus Tachycardia'] = '#09ff08';
        peakColorArray['Sinus Bradycardia'] = '#09ff08';
        peakColorArray['PAC'] = '#09ff08';
        peakColorArray['PVC'] = '#09ff08';
        peakColorArray['Ventricular Tachycardia'] = '#09ff08';
        peakColorArray['AFib'] = '#9900FF';
        peakColorArray['Sinus Pause'] = '#09ff08';
        peakColorArray['P absent'] = '#09ff08';
        peakColorArray['Irregular RR'] = '#09ff08';
        peakColorArray['Irregular PR'] = '#09ff08';
        peakColorArray['Short QT'] = '#09ff08';
        peakColorArray['Prolonged QT'] = '#09ff08';
        peakColorArray['Undiagnosed'] = '#09ff08';

        function chunkPeakArray(peakArray, start = 0) {
            //console.log(peakArray);
            let presults = [];
            var minIndex = 0;
            var breakVal = (deviceFrequency * (window.pageIndex + 1));
            var z;
            var temRpeakindex;

            presults.push([]);
            for (var i = 0; i < peakArray.length; i++) {
                if (window.pageIndex * deviceFrequency < peakArray[i].rpeakindex) {
                    if (peakArray[i].rpeakindex > breakVal) {
                        presults.push([]);
                        minIndex = minIndex + 1;
                        breakVal = breakVal + deviceFrequency;
                    }
                    temRpeakindex = parseInt(peakArray[i].rpeakindex) - ((minIndex + window.pageIndex) * deviceFrequency);
                    presults[minIndex].push({
                        x: temRpeakindex,
                        y: parseFloat(0.8),
                        color: peakColorArray[peakArray[i].arrhythmia],
                        beat: parseInt(peakArray[i].indexcounter),
                        hr: parseInt(peakArray[i].heartrate),
                        rr: parseInt(peakArray[i].rr_interval),
                        arrhythmia: peakArray[i].arrhythmia,
                        peaktime: peakArray[i].peaktime
                    });
                }
            }
            return presults;
        }

            var sliceStart = 0;
            var sliceEnd = sliceStart + 15001;
            var sliceActStart = 0;
            var sliceActEnd = sliceActStart + 61;
            var currentEcgArray = this.ecgValues.slice(sliceStart, sliceEnd);
            var currentActArray = this.activityValues.slice(sliceActStart, sliceActEnd);
            //console.log(test);
            var tempChart;
            var colorArray = ['#98FB98', '#f1e4e4', '#f7e0a3', '#f09c67'];

            var acti = 125;
            var actarr = [];
            var tttarr1 = [];

            for (var i = 0; i < currentActArray.length; i++) {
                actarr.push({
                    x: acti,
                    y: 0.5,
                    color: colorArray[currentActArray[i]]
                });
                acti = acti + 150;
            }
           
            var prevChart = window.prevChart;
            if (window.beatArr.length > 0 && window.container != window.prevChart) {
                for (var i = 0; i < window.beatArr.length; i++) {
                    var j = window.container * deviceFrequency;
                    var xBeat = parseInt(window.beatArr[i]) - j;
                    console.log('xBeat', xBeat);
                    chart.xAxis[0].removePlotBand('plotband-' + xBeat);
                }
            }

            var currentDataSet = [];
            var self = this;
            this.state.chart.series[0].setData(currentEcgArray, false);
            this.state.chart.series[1].setData(actarr, false);
            this.state.chart.series[2].setData(this.state.result3[0], true);
            //console.log(this.state.parr);
            
            console.log('upmoc', window.prevChart);

            var chart1 = this.state.charts[0];
            var tempChart = this.state.charts[window.prevChart];

            var lineWidth = 2

            if (this.state.charts[window.prevChart] != undefined) {
                if (this.state.charts[window.prevChart].draggablePlotLine != undefined) {
                    this.state.charts[window.prevChart].draggablePlotLine.destroy();
                }
            }
           if(this.props.match.params.start!=undefined && window.diaryFlag==1)
           {
            window.diaryFlag=2;
            window.stripXValue=this.props.match.params.xValue;
           }
            this.state.charts[0].draggablePlotLine = this.state.charts[0].renderer.rect(this.state.charts[0].xAxis[0].toPixels(window.stripXValue) - this.state.charts[0].xAxis[0].toPixels(25), 10, 50, 40, 5)
                .attr({
                    'stroke-width': 2,
                    opacity: 0.5,
                    fill: 'grey',
                    zIndex: 3
                })
                .add();
                window.prevChart=0;
                if(window.stripXValue+1250 >15000)
                {
                  this.state.chart.xAxis[0].setExtremes(window.stripXValue - 1250, window.stripXValue , true, false);
                }
                else
                {
                  this.state.chart.xAxis[0].setExtremes(window.stripXValue - 1250, window.stripXValue +1250, true, false);
                }
            console.log("A I umcoc");

            if (this.state.charts[0].container.onmousemove != undefined) {
                this.state.charts[0].container.onmousemove = function(e) {
                    return false;
                }
            }
            var mainCChart = this.state.chart;
            this.state.charts[0].container.onmousemove = function(e) {
                if (chart1.draggablePlotLine.drag) {
                    let normalizedEvent = chart1.pointer.normalize(e),
                        extremes = {
                            left: chart1.plotLeft,
                            right: chart1.plotLeft + chart1.plotWidth
                        };

                    if (e.chartX >= extremes.left && e.chartX <= extremes.right) {
                        var pointValue = chart1.xAxis[0].toValue(e.chartX);
                        if (pointValue - 1250 <= 0) {
                            mainCChart.xAxis[0].setExtremes(pointValue, pointValue + 2500, true, false);
                        } else {
                            mainCChart.xAxis[0].setExtremes(pointValue - 1250, pointValue + 1250, true, false);
                        }
                        chart1.draggablePlotLine.attr({
                            x: e.chartX
                        })
                    }
                }
            }

            this.state.charts[0].draggablePlotLine.element.onmousedown = function() {
                chart1.draggablePlotLine.drag = true;
            }

            this.state.charts[0].draggablePlotLine.element.onmouseup = function() {
                chart1.draggablePlotLine.drag = false;
            }  
    }

    updateRpeaks(data) {
        this.setState({
            rpeaks: data
        })
    }

    generateReport(e) {
        const userid = this.props.match.params.userid;
        e.currentTarget.innerHTML = "PLease Wait...";
        //
        axios
            .get('https://web.mybiocalculus.com/html/welcome/generateReportTest/' + userid)
            .then(res => {

            })
            .catch(err => {

            });
    }

    mailReport(e) {

           // document.getElementById("chart_tab_email").innerHTML="<img id=\"emailImg\" src=\"../../dist/img/Email_white.png\"/>";
   
        const userid = this.props.match.params.userid;
        axios
            .get(apiUrl + 'api/users/mailReport?userid=' + userid)
            .then(res => {
                alert('Mail send successfully');
                //if(document.getElementById("chart_tab_email")!=null){document.getElementById("chart_tab_email").innerHTML="<img id=\"emailImg\" src=\"../../dist/img/Email.png\"/>";}
            })
            .catch(err => {
                console.log(err)
                alert('Something went wrong');
                //if(document.getElementById("chart_tab_email")!=null){document.getElementById("chart_tab_email").innerHTML="<img id=\"emailImg\" src=\"../../dist/img/Email.png\"/>";}
            });
    }

    splitFn(value) {
        var res = value.split("_");
        var name = res[0].substring(0, 4) + '-' + res[0].substring(4, 6) + '-' + res[0].substring(6, 8) + ' :: ' + res[1].substring(0, 2) + ':' + res[1].substring(2, 4) + ':' + res[1].substring(4, 6);
        res[0] = name;
        return res;
    }

    resetGraph(e) {
        var it = 0;
        var currentChart = this.state.charts;
        var currentNsrarr = this.state.defarr;
        var myRGVar = setInterval(function() {
            currentChart[it].series[0].setData(currentNsrarr[it], true);
            it = it + 1;
            if (it > currentNsrarr.length - 1) {
                clearInterval(myRGVar);
            }
        }, 10);
    }

    listenScrollEvent(e) {

        var databaseid = this.state.databaseid;
        var userid = this.props.match.params.userid;
        var dis = this;
        var ee = e.target;

        var element_height = 53.6833333;
        var positive = Math.abs(ee.scrollTop);
        var divided = positive / element_height;
        
        if (divided != 0) {
            var round = Math.floor(divided);
        } else {
            var round = 0;
        }

        if (window.timer !== null) {
            clearTimeout(window.timer);
        }
        window.timer = setTimeout(function() {

            var height = document.getElementById("selWrap").scrollHeight;
            
            round = Math.floor(positive / (height / window.sc));
            var start = round;
            var end = round + 9;
            dis.setState({
                start: start,
                end: end,
            });
            dis.renderNewSmallCharts();
        }, 500);

    }

    gainBtn(e) {
        var buttonId = e.currentTarget.id;
        var gainValue = document.getElementById('gainValue').value;
        var it = 0;
        var charts = this.state.charts;
        var arCount = this.state.datasetMnt - 1;
        if (gainValue == 1) {
            gainMin = -0.0005;
            gainMax = 0.0015;
        } else if (gainValue == 2) {
            gainMin = -0.0005;
            gainMax = 0.0008;
        } else if (gainValue == 3) {
            gainMin = -0.0005;
            gainMax = 0.0005;
        }
        var scaleUp = setInterval(function() {
            charts[it].update({
                yAxis: [{
                    min: gainMin,
                    max: gainMax
                }],
            })
            it = it + 1;
            if (it > arCount) {
                clearInterval(scaleUp);
            }
        }, 1);
    }

    checkFullVerify() {
        var fullVerify=this;
        worker15.postMessage({ name:'checkFullVerify',url: apiUrl + 'api/common/checkFullVerify?userid=' + this.props.match.params.userid});
        worker15.onmessage = function (event) {};
        worker15.addEventListener("message", function (res) {
            fullVerify.setState({
                fullVerifyStatus: res.data
            })
          });
    }

    renderResumeChart(userid, dbId, cont, strip_val) {
        var newThis = this,
        val, colorArray = [];
        window.chartid = 0;
        window.container = cont; //container id stored in global variable
        //console.log('window.container', window.container);
        window.stripXValue = strip_val - (cont * 15000); //got the location in the xaxis
        
        /*************Remove Plotband***********************/

        if (window.beatArr.length > 0 && window.chartid != window.prevChart) {
            for (var i = 0; i < window.beatArr.length; i++) {
                var j = window.container * deviceFrequency;
                var xBeat = parseInt(window.beatArr[i]) - j;


                console.log('xBeat', xBeat);
                window.chart.xAxis[0].removePlotBand('plotband-' + xBeat);
            }
            //document.getElementById("beatSlected").value='[]';
            // beatArr=[];
        }

        /*************Remove Plotband***********************/
        if (newThis.state.charts[window.container]) {
            val = newThis.state.charts[window.container].xAxis[0].toValue(window.stripXValue);
        }

        var sliceStart = cont * deviceFrequency;
        var sliceEnd = sliceStart + deviceFrequency + 1;
        var sliceActStart = window.container * 60;
        var sliceActEnd = sliceActStart + 61;

        var ecgArray = newThis.state.mainEcgArray.slice(0, 15001);
        var curActArray = newThis.state.mainActivityArray.slice(0, 61);

        if (val > ecgArray.length) {
            val = ecgArray.length - 500;
        }

        var acti = 125;
        var actarr = [];
        var tttarr1 = [];


        for (var i = 0; i < curActArray.length; i++) {
            actarr.push({
                x: acti,
                y: 0.5,
                color: colorArray[curActArray[i]]
            });
            acti = acti + 250;
        }
        
        console.log('testResult3', newThis.state.result3);
        //console.log(result3[window.container]);
        newThis.state.chart.series[0].setData(ecgArray, false);
        newThis.state.chart.series[1].setData(actarr, false);
        newThis.state.chart.series[2].setData(newThis.state.result3[0], false);
        
        if (newThis.state.charts[window.prevChart] != undefined) {
            if (newThis.state.charts[window.prevChart].draggablePlotLine != undefined) {
                newThis.state.charts[window.prevChart].draggablePlotLine.destroy();
            }
        }
        var chart1 = newThis.state.charts[0];

        window.prevChart = 0;

        var lineWidth = 2

        chart1.draggablePlotLine = chart1.renderer.rect(newThis.state.charts[0].xAxis[0].toPixels(strip_val) - newThis.state.charts[0].xAxis[0].toPixels(25), 10, 50, 40, 5)
            .attr({
                'stroke-width': 2,
                opacity: 0.5,
                fill: 'grey',
                zIndex: 3
            })
            .add();
        newThis.state.chart.xAxis[0].setExtremes(strip_val - 500, strip_val + 500, true, false);

        if (newThis.state.charts[0].container.onmousemove != undefined) {
            newThis.state.charts[0].container.onmousemove = function(e) {
                return false;
            }
        }

        newThis.state.charts[0].container.onmousemove = function(e) {
            if (chart1.draggablePlotLine != undefined) {
                if (chart1.draggablePlotLine.drag) {

                    let normalizedEvent = chart1.pointer.normalize(e),
                        extremes = {
                            left: chart1.plotLeft,
                            right: chart1.plotLeft + chart1.plotWidth
                        };

                    if (e.chartX >= extremes.left && e.chartX <= extremes.right) {
                        var pointValue = chart1.xAxis[0].toValue(e.chartX);
                        if (pointValue - 1250 <= 0) {
                            newThis.state.chart.xAxis[0].setExtremes(0, pointValue + 2500, true, false);
                        } else {
                            newThis.state.chart.xAxis[0].setExtremes(pointValue - 1250, pointValue + 1250, true, false);
                        }
                        chart1.draggablePlotLine.attr({
                            x: e.chartX
                        })
                    }
                }
            }
        }

        newThis.state.charts[0].draggablePlotLine.element.onmousedown = function() {
            chart1.draggablePlotLine.drag = true;
        }

        newThis.state.charts[0].draggablePlotLine.element.onmouseup = function() {
            chart1.draggablePlotLine.drag = false;
        }
    }


    renderNewChart(userid, dbId, step, start, end ) {
        var arr = [],
            charts = [],
            arr2 = [],
            peakarr = [],
            barClick = 0,
            pointValue, tempChart, tempCont = 0;
        var starr = [],
           
            beatArr = [],
            defarr = [],
            plotband = [],
            piec;
        var maximumval;
        var b;
        var js_array = [];
        var chart;
        var minVal, maxVal, val, val2;
        var rarr = [];
        var result3, colorArray = [];
        var rpeakArray = [];
        /*  var markerAarray = [],*/
        var result4 = [];
        var peakColorArray = [];
       
        peakColorArray['Normal Sinus Rhythm'] = '#09ff08';
        peakColorArray['Sinus Tachycardia'] = '#09ff08';
        peakColorArray['Sinus Bradycardia'] = '#09ff08';
        peakColorArray['PAC'] = '#09ff08';
        peakColorArray['PVC'] = '#09ff08';
        peakColorArray['Ventricular Tachycardia'] = '#09ff08';
        peakColorArray['AFib'] = '#9900FF';
        peakColorArray['Sinus Pause'] = '#09ff08';
        peakColorArray['P absent'] = '#09ff08';
        peakColorArray['Irregular RR'] = '#09ff08';
        peakColorArray['Irregular PR'] = '#09ff08';
        peakColorArray['Short QT'] = '#09ff08';
        peakColorArray['Prolonged QT'] = '#09ff08';
        peakColorArray['Undiagnosed'] = '#09ff08';
        var userid = userid;
        var databaseid = dbId;
        var selfNew = this;
        var mainEcgArray, mainActivityArray = [];
        var datasetMnt = 0;

        //Split the array in chunks with given size
        function chunkArray(myArray, chunk_size) {
            let results = [];

            while (myArray.length) {
                results.push(myArray.splice(0, chunk_size))
            }

            return results;
        };

        function chunkPeakArray(peakArray, start = 0) {
            let presults = [];
            var minIndex = 0;
            var breakVal = (start + 1) * deviceFrequency;
            var z;
            var temRpeakindex
            presults.push([]);
            for (var i = 0; i < peakArray.length; i++) {
                if (start * deviceFrequency < peakArray[i].rpeakindex) {
                    if (peakArray[i].rpeakindex > breakVal) {
                        presults.push([]);
                        minIndex = minIndex + 1;
                        breakVal = breakVal + deviceFrequency;
                    }
                    temRpeakindex = parseInt(peakArray[i].rpeakindex) - ((minIndex + start) * deviceFrequency);
                    presults[minIndex].push({
                        x: temRpeakindex,
                        y: parseFloat(0.8),
                        color: peakColorArray[peakArray[i].arrhythmia],
                        beat: parseInt(peakArray[i].indexcounter),
                        hr: parseInt(peakArray[i].heartrate),
                        rr: parseInt(peakArray[i].rr_interval),
                        arrhythmia: peakArray[i].arrhythmia,
                        peaktime: peakArray[i].peaktime
                    });
                }
            }
            return presults;
        }

        function chunkMarkerArray(markArrayX, markArrayY, start = 0) {

            var ppeakIndex = markArrayX.split(',');
            var ppeakAmp = markArrayY.split(',');
            let mresults = [];
            var minIndex = 0;
            var breakVal = (start + 1) * deviceFrequency;
            var z;
            var temindex;
            mresults.push([]);
            for (var i = 0; i < ppeakIndex.length; i++) {
                if (ppeakIndex[i] > start * deviceFrequency) {
                    if (ppeakIndex[i] > breakVal) {
                        mresults.push([]);
                        minIndex = minIndex + 1;
                        breakVal = breakVal + deviceFrequency;
                    }
                    temindex = parseInt(ppeakIndex[i]) - ((minIndex + start) * deviceFrequency);
                    mresults[minIndex].push([temindex, parseFloat(ppeakAmp[i])]);
                }
            }
            return mresults;
        }

        function clickFunction(event, that) {
            
            var containerId = that.renderTo.id; //got the Container ID
            console.log('containerId',);
            
            window.container = parseInt(containerId.replace('container', ''));
           window.chartid= window.container - selfNew.state.start;
            console.log('window.container', window.container);
            window.stripXValue = event.chartX; //got the location in the xaxis
            /*************Remove Plotband***********************/
            if (window.beatArr.length > 0 && window.chartid != window.prevChart) {
                for (var i = 0; i < window.beatArr.length; i++) {
                    var j =  (parseInt(window.container)) * deviceFrequency;
                    var xBeat = parseInt(window.beatArr[i]) - j;
                   // console.log('xBeat', xBeat);
                    chart.xAxis[0].removePlotBand('plotband-' + xBeat);
                }
            }
            /*************Remove Plotband***********************/
            val = charts[window.container].xAxis[0].toValue(event.chartX);
            //console.log(selfNew.state.mainEcgArray);
            var sliceStart = window.container * deviceFrequency;
            var sliceEnd = sliceStart + deviceFrequency + 1;
            var sliceActStart = window.container * 60;
            var sliceActEnd = sliceActStart + 61;
            var ecgArray = selfNew.state.mainEcgArray.slice(sliceStart, sliceEnd);
            var curActArray = selfNew.state.mainActivityArray.slice(sliceActStart, sliceActEnd);
            if (val > ecgArray.length) {
                val = ecgArray.length - 500;
            }
            var acti = 125;
            var actarr = [];
            var tttarr1 = [];
            for (var i = 0; i < curActArray.length; i++) {
                actarr.push({
                    x: acti,
                    y: 0.5,
                    color: colorArray[curActArray[i]]
                });
                acti = acti + 250;
            }

            chart.series[0].setData(ecgArray, false);
            chart.series[1].setData(actarr, false);
            chart.series[2].setData(selfNew.state.result3[window.chartid], false);

            if (charts[window.prevChart] != undefined) {
                if (charts[window.prevChart].draggablePlotLine != undefined) {
                    charts[window.prevChart].draggablePlotLine.destroy();
                }
            }
            var chart1 = that;

            window.prevChart = window.chartid;

            var lineWidth = 2

            chart1.draggablePlotLine = chart1.renderer.rect(event.chartX - charts[window.chartid].xAxis[0].toPixels(25), 10, 50, 40, 5)
                .attr({
                    'stroke-width': 2,
                    opacity: 0.5,
                    fill: 'grey',
                    zIndex: 3
                })
                .add();
         //   console.log("a i clickFunction");

            if (charts[0].container.onmousemove != undefined) {
                charts[0].container.onmousemove = function(e) {
                    return false;
                }
            }

            charts[window.chartid].container.onmousemove = function(e) {
                if (chart1.draggablePlotLine != undefined) {
                    if (chart1.draggablePlotLine.drag) {

                        let normalizedEvent = chart1.pointer.normalize(e),
                            extremes = {
                                left: chart1.plotLeft,
                                right: chart1.plotLeft + chart1.plotWidth
                            };

                        if (e.chartX >= extremes.left && e.chartX <= extremes.right) {
                            pointValue = chart1.xAxis[0].toValue(e.chartX);
                            if (pointValue - 1250 <= 0) {
                                chart.xAxis[0].setExtremes(0, pointValue + 2500, true, false);
                            } else {
                                chart.xAxis[0].setExtremes(pointValue - 1250, pointValue + 1250, true, false);
                            }
                            chart1.draggablePlotLine.attr({
                                x: e.chartX
                            })
                        }
                    }
                }
            }

            charts[window.chartid].draggablePlotLine.element.onmousedown = function() {
                chart1.draggablePlotLine.drag = true;
            }

            charts[window.chartid].draggablePlotLine.element.onmouseup = function() {
                chart1.draggablePlotLine.drag = false;
            }
            if (val - 1250 <= 0) {
                chart.xAxis[0].setExtremes(0, val + 2500, true, false);
            } else {
                chart.xAxis[0].setExtremes(val - 1250, val + 1250);
            }
        }

        function renderSmallCharts(clickPointIndex = 0) {
            var i = 0;
            var t = 0;
            var limit=9;
            var stcolor = "#09ff08",defcolour = "#09ff08";
            if(window.sc<9)
            {
                limit=window.sc-1;
            }
            
            //console.log('renderSmallCharts');
            var currentDataSet = [];
             //console.log('strip count ',window.sc);
            for (var j = 0; j < window.sc; j++) {
                var newDiv = document.createElement('div');
                newDiv.id = 'container' + j;
                newDiv.style = 'min-width: 200px; height: 60px; margin: 0 auto';
                var newSpan = document.createElement('div');
                newSpan.id = 'span' + j;
                newSpan.style = 'color:#ffff; height: 21px;';
                document.getElementById('selWrap').appendChild(newSpan);
                document.getElementById('selWrap').appendChild(newDiv);
            }

            var myVar = setInterval(function() {
                var sliceStartI = i * deviceFrequency;
                var sliceEndI = sliceStartI + deviceFrequency + 1;
                //console.log('selfNew.state.mainEcgArray',selfNew);
                currentDataSet = selfNew.state.mainEcgArray.slice(sliceStartI, sliceEndI);
                var arrayIndex = 0;
                var k = 0;
                starr.push([]);
                defarr.push([]);

                if (selfNew.state.result3[i] != undefined) {	
                    if(selfNew.state.result3[i][0]!= undefined){

                    var time1 = document.createTextNode(selfNew.state.result3[i][0].peaktime);
                    document.getElementById('span' + i).appendChild(time1);
                
                for (var j = 0; j < currentDataSet.length; j++) {

                    if (selfNew.state.result3[i][k] != null && (j + 70 == selfNew.state.result3[i][k].x || selfNew.state.result3[i][k].x < 70)) {
                        stcolor = "09ff08";     
                        defcolour = "09ff08";
                        if (selfNew.state.result3[i][k].arrhythmia == 'Normal Sinus Rhythm') {                          
                            stcolor = selfNew.state.result3[i][k].color;
                        } else if (selfNew.state.result3[i][k].arrhythmia == 'Sinus Tachycardia') {
                            stcolor = selfNew.state.result3[i][k].color;
                        } else if (selfNew.state.result3[i][k].arrhythmia == 'Sinus Bradycardia') {                          
                            stcolor = selfNew.state.result3[i][k].color;
                        } else if (selfNew.state.result3[i][k].arrhythmia == 'Sinus Pause') {                           
                            stcolor = selfNew.state.result3[i][k].color;
                        } else if (selfNew.state.result3[i][k].arrhythmia == 'PAC') {                          
                            stcolor = selfNew.state.result3[i][k].color;
                        } else if (selfNew.state.result3[i][k].arrhythmia == 'PVC') {                         
                            stcolor = selfNew.state.result3[i][k].color;
                        } else if (selfNew.state.result3[i][k].arrhythmia == 'Ventricular Tachycardia') {                          
                            stcolor = selfNew.state.result3[i][k].color;
                        } else if (selfNew.state.result3[i][k].arrhythmia == 'AFib') {                      
                            stcolor = selfNew.state.result3[i][k].color;
                        } else if (selfNew.state.result3[i][k].arrhythmia == 'P absent') {                      
                            stcolor = selfNew.state.result3[i][k].color;
                        } else if (selfNew.state.result3[i][k].arrhythmia == 'Irregular RR') {                      
                            stcolor = selfNew.state.result3[i][k].color;
                        } else if (selfNew.state.result3[i][k].arrhythmia == 'Irregular PR') {                    
                            stcolor = selfNew.state.result3[i][k].color;
                        } else if (selfNew.state.result3[i][k].arrhythmia == 'Short QT') {                    
                            stcolor = selfNew.state.result3[i][k].color;
                        } else if (selfNew.state.result3[i][k].arrhythmia == 'Prolonged QT') {                 
                            stcolor = selfNew.state.result3[i][k].color;
                        } else if (selfNew.state.result3[i][k].arrhythmia == 'Undiagnosed') {      
                            stcolor = selfNew.state.result3[i][k].color;
                        }
                        if (k < selfNew.state.result3[i].length - 1) {
                            k++;
                        }
                    }
                    defarr[i].push({
                        x: j,
                        y: parseFloat(currentDataSet[j]),
                        segmentColor: stcolor
                        //segmentColor: 'black'
                    });

                    starr[i].push({
                        x: j,
                        y: parseFloat(currentDataSet[j]),
                        segmentColor: stcolor
                    });   
                }
                console.log("currentDataSet",currentDataSet);
            }
        }
                charts[i] = new Highcharts.Chart('container' + i, {
                    credits: {
                        enabled: false
                    },
                    chart: {
                        height: 4 + '%',
                        resetZoomButton: {
                            theme: {
                                display: 'none'
                            },
                        },
                        events: {
                            click: function(e) {
                                /* Do something with the event */
                                clickFunction(e, this); // ...which means you can access it from inside the click handler function
                            }
                        },
                        zoomType: '',
                    },

                    navigator: {
                        enabled: false
                    },

                    xAxis: [{
                        min: 0,
                        max: deviceFrequency,
                        gridLineWidth: 0,
                        minorGridLineWidth: 0,
                        minorTicks: false,
                        labels: {
                            enabled: false
                        },
                        title: {
                            enabled: false
                        },
                        visible: false

                    }],
                    yAxis: [{
                        min: gainMin,
                        max: gainMax,
                        gridLineWidth: 0,
                        minorGridLineWidth: 0,
                        minorTicks: false,
                        labels: {
                            enabled: false
                        },
                        title: {
                            enabled: false
                        },
                    }],
                    rangeSelector: {
                        enabled: false
                    },
                    plotOptions: {
                        series: {
                            turboThreshold: deviceFrequency,
                        },
                    },
                    title: {
                        text: ''
                    },

                    series: [{
                        type: 'coloredline',
                        data: currentDataSet,
                        color: '#09ff08',
                        allowPointSelect: false,
                        animation: false,
                        enableMouseTracking: false,
                        lineWidth: 2,
                        marker: {
                            enabled: false
                        },
                        shadow: false,
                        stickyTracking: false,
                        showInLegend: false
                    }]
                });
                charts[i].series[0].setData(starr[i], true)
                if (i == 0) {
                    clickContainerIndex = clickPointIndex;
                    window.stripXValue = 100;
                    window.container = 0;
                    window.chartid = 0;
                    window.pageIndex = 0;
                }

                window.prevChart = clickContainerIndex;
                if (i == clickContainerIndex) {
                    charts[i].draggablePlotLine = charts[i].renderer.rect(charts[i].xAxis[0].toPixels(50) - charts[i].xAxis[0].toPixels(25), 10, 50, 40, 5)
                        .attr({
                            'stroke-width': 2,
                            opacity: 0.5,
                            fill: 'grey',
                            zIndex: 3
                        })
                        .add();
                    tempChart = charts[i];
                   
                    if (charts[i].container.onmousemove != undefined) {
                        charts[i].container.onmousemove = function(e) {
                            return false;
                        }
                    }
                    var stripIndex = i;
                    charts[stripIndex].container.onmousemove = function(e) {
                        if (charts[stripIndex].draggablePlotLine.drag) {
                            let normalizedEvent = charts[stripIndex].pointer.normalize(e),
                                extremes = {
                                    left: charts[stripIndex].plotLeft,
                                    right: charts[stripIndex].plotLeft + charts[stripIndex].plotWidth
                                };
                            if (e.chartX >= extremes.left && e.chartX <= extremes.right) {
                                var pointValue = charts[stripIndex].xAxis[0].toValue(e.chartX);
                                if (pointValue - 1250 <= 0) {
                                    chart.xAxis[0].setExtremes(0, pointValue + 2500, true, false);
                                } else {
                                    chart.xAxis[0].setExtremes(pointValue - 1250, pointValue + 1250, true, false);
                                }
                                charts[stripIndex].draggablePlotLine.attr({
                                    x: e.chartX
                                })
                            }
                        }
                    }
                    charts[stripIndex].draggablePlotLine.element.onmousedown = function() {
                        charts[stripIndex].draggablePlotLine.drag = true;
                    }

                    charts[stripIndex].draggablePlotLine.element.onmouseup = function() {
                        charts[stripIndex].draggablePlotLine.drag = false;
                    }
                }
                i = i + 1;
                
                if (i > limit) {
                    clearInterval(myVar);
                    if(selfNew.props.match.params.start!=undefined)
                      {
                         setTimeout(function() {
                         console.log($('#container5' ).position());
                        $('#selWrap').scrollTop($('#container'+ selfNew.props.match.params.start).position().top - ($('#selWrap').position().top));
                           console.log($('#container5' ).position());
                         },1000);
                      }
                }
            }, 5);
        }

        // Function to plot the background graph
        function renderMainChart(self) {   
            maximumval = 2500;
            b = 0;
           // var self = this;

            // Create the chart
            chart = new Highcharts.StockChart('tt_container', {
                exporting: {
                    enabled: false
                },
                scrollbar: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
                chart: {

                    type: 'coloredline',
                    height: 20 + '%',

                    resetZoomButton: {
                        theme: {
                            display: 'none'
                        },
                    },
                    zoomType: '',
                },

                navigator: {
                    enabled: false
                },

                xAxis: [{
                    min: 0, //setting min to 0
                    max: 2500,
                    events: {

                        setExtremes: function(e) {
                            //val = e.min + 500; 
                            if (e.min <= 500) {
                                val = e.min;
                            } else {
                                val = e.min + 500;
                            }
                            tempChart = charts[window.prevChart];
                            if (tempChart != undefined) {
                                if (tempChart.draggablePlotLine != undefined) {
                                    val2 = tempChart.xAxis[0].toPixels(val);
                                    tempChart.draggablePlotLine.attr({
                                        x: val2
                                    })
                                }
                            }
                        },
                    },
                    scrollbar: {
                        enabled: true
                    },
                    labels: {
                        enabled: false
                    },

                    tickInterval: 50,
                    minorTicks: true,
                    minorTickInterval: 10,
                    gridLineWidth: 1,
                    gridLineColor: '#D3D3D3'

                }],
                yAxis: [{
                        min: 0,
                        max: 10,
                        reversed: true,
                        gridLineWidth: 0,
                        minorGridLineWidth: 0,
                        minorTicks: false,

                        labels: {
                            enabled: true
                        },
                        title: {
                            enabled: false
                        },
                        visible: false

                    },
                    {
                        min: -0.002,
                        max: 0.002,
                        tickPositions: [-0.0020, -0.0015, -0.0010, -0.0005, 0.0000, 0.0005, 0.0010, 0.0015, 0.0020],
                        tickInterval: 0.0005,
                        gridLineWidth: 3,
                        minorGridLineWidth: 1,
                        minorTicks: true,
                        minorTickInterval: 0.0001,

                        gridLineWidth: 2,
                        gridLineColor: '#D3D3D3',
                        labels: {
                            enabled: false
                        },
                        title: {
                            enabled: false
                        },

                    },
                    {
                        min: 0,
                        max: 10,
                        gridLineWidth: 0,
                        minorGridLineWidth: 0,
                        minorTicks: false,

                        labels: {
                            enabled: true
                        },
                        title: {
                            enabled: false
                        },
                        visible: false

                    }
                ],
                rangeSelector: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        turboThreshold: 15500,
                        colorByPoint: false
                    },

                },
                title: {
                    text: ''
                },

                series: [{
                    data: [],
                    yAxis: 1,
                    xAxis: 0,
                    color: '#09ff08',
                    states: {
                        inactive: {
                            opacity: 1
                        }
                    },

                    allowPointSelect: false,
                    animation: false,
                    enableMouseTracking: false,
                    lineWidth: 2
                ,
                    marker: {
                        enabled: false
                    },
                    shadow: false,
                    stickyTracking: false,
                    showInLegend: false
                }]
            });
            chart.showLoading(' Please wait while the ECG is being loaded  <i class="fa fa-spin fa-spinner"></i>');
            chart.addSeries({
                yAxis: 0,
                type: 'column',
                data: [],
                pointWidth: 150,
                inverted: true,
                tooltip: {
                    enabled: false
                },
                states: {

                    inactive: {
                        opacity: 1
                    }
                },

                allowPointSelect: false,
                animation: false,
                enableMouseTracking: false,
                lineWidth: 2,
                shadow: false,
                stickyTracking: false,
                showInLegend: false
            }, true);
            chart.addSeries({
                name: 'R Peak',
                yAxis: 1,
                data: [],

                lineWidth: 0,
                marker: {
                    enabled: true,
                    radius: 4,

                    symbol: 'circle'
                },

                tooltip: {

                    enabled: false

                },
                states: {
                    hover: {
                        lineWidthPlus: 0
                    },
                    inactive: {
                        opacity: 1
                    }
                },
                enableMouseTracking: false,
            }, true);

            return chart;
        };


        if (step == 2) {
            this.state.chart.destroy();
            this.setState({
                databaseid: databaseid
            });
        }

        renderMainChart(this);

        this.setState({
            result3: []
        });
        this.setState({
            defarr: []
        });
        this.setState({
            starr: []
        });
 
        var setEcgData = res => {
            for (var i = this.state.start; i < res.data.length; i++) {
                window.mainArray[i].push(res.data[i]);
            }
            if (!this.preEcgValues) {
                this.preEcgValues = res.data;
                this.ecgValues = res.data.map(e => e[0]).reduce((a, b) => a.concat(b));
                this.activityValues = res.data.map(e => e[1]).reduce((a, b) => a.concat(b));
                this.peakValues = res.data.map(e => e[2]).reduce((a, b) => a.concat(b));
            } else {

                if (this.state.scrollStatus === 'next') {
                    this.preEcgValues = this.preEcgValues.filter(
                        (e, i) => (i > this.state.limitScrollStart - 1)
                    );
                    this.preEcgValues = this.preEcgValues.concat(res.data)
                } else if (this.state.scrollStatus === 'prev') {
                    this.preEcgValues = this.preEcgValues.filter(
                        (e, i) => i < (this.state.limitScrollStart - 1)
                    );
                    this.preEcgValues = res.data.concat(this.preEcgValues)
                }
                this.ecgValues = this.preEcgValues.map(e => e[0]).reduce((a, b) => a.concat(b));
                this.activityValues = this.preEcgValues.map(e => e[1]).reduce((a, b) => a.concat(b));
                this.peakValues = this.preEcgValues.map(e => e[2]).reduce((a, b) => a.concat(b));
            }

            console.log("preEcgValues",this.preEcgValues);
            console.log("this.ecgValues.length",this.ecgValues.length);
            datasetMnt = Math.floor(this.ecgValues.length / deviceFrequency)
            //console.log("datasetMnt",datasetMnt);
            if (this.ecgValues.length % deviceFrequency > 0) {
                datasetMnt += 1;
            }

            /*mainEcgArray = this.ecgValues;
            mainActivityArray = this.activityValues;*/
            this.updateRpeaks(this.peakValues);
            var graphPointIndex = 0;
            if (this.state.scrollStatus === 'next') {
                if (clickContainerIndex > 2) {
                    graphPointIndex = parseInt(clickContainerIndex) - 2;
                }
            } else if (this.state.scrollStatus === 'prev') {
                graphPointIndex = 4;
                if (clickContainerIndex < 2) {
                    graphPointIndex = parseInt(clickContainerIndex) + 2;
                }
            }
            result3 = chunkPeakArray(this.peakValues, this.state.peakStart);
            window.result3 = result3;
            this.setState({
                result3: result3
            });
            
            var acti = 125;
            var counteri = 249;
            var counter = 0;
            var t = 0;
            var actarr = [];
            colorArray = ['#98FB98', '#f1e4e4', '#f7e0a3', '#f09c67']


            var sliceActStart = graphPointIndex * window.sc;
            var sliceActEnd = sliceActStart + window.sc + 1;
            var curDataArray = this.activityValues.slice(sliceActStart, sliceActEnd);
            for (var i = 0; i < curDataArray.length; i++) {
                actarr.push({
                    x: acti,
                    y: 0.5,
                    color: colorArray[curDataArray[i]]
                });
                acti = acti + 250;
            }

            chart.hideLoading();
            var sliceStart = graphPointIndex * deviceFrequency;
            var sliceEnd = sliceStart + deviceFrequency + 1;
            // console.log('ECG Valuess',this.ecgValues.slice(sliceStart, sliceEnd));
            chart.series[0].setData(this.ecgValues.slice(sliceStart, sliceEnd), true);
            chart.series[1].setData(actarr, true);
            chart.series[2].setData(result3[graphPointIndex], true);

            renderSmallCharts(graphPointIndex);

            this.setState({
                datasetMnt: datasetMnt
            });
            this.setState({
                mainEcgArray: this.ecgValues
            });
            //console.log('mainOne', this.state.mainEcgArray);
            this.setState({
                mainActivityArray: this.activityValues
            });
            this.setState({
                result3: result3
            });
            this.setState({
                charts: charts
            });
            this.setState({
                chart: chart
            });
            this.setState({
                defarr: defarr
            });
            this.setState({
                starr: starr
            });
        }

        //this.state.charts[0].destroy();

        for (var k = 0; k < this.state.charts.length; k++) {
            if (this.state.charts[k] != undefined) {
                //console.log('chart length',this.state.charts);
                this.state.charts[k].destroy();
            }
        }
 
         var selfOne=this;
          
         worker2.postMessage({ name:'getEcgNewData',url: apiUrl + 'api/common/getEcgNewData?start=' + start + '&end=9&userid=' + userid + '&databaseid=' + databaseid});
         worker2.onmessage = function (event) {};
         worker2.addEventListener("message", function (res) {
             
             for (var i = selfOne.state.start; i < res.data.length; i++) {
                 window.mainArray[i].push(res.data[i]);
             } 
             if (!selfOne.preEcgValues) {
                
                 selfOne.preEcgValues = res.data;
 
                 selfOne.ecgValues = res.data.map(e => e[0]).reduce((a, b) => a.concat(b));
                 selfOne.activityValues = res.data.map(e => e[1]).reduce((a, b) => a.concat(b));
                 selfOne.peakValues = res.data.map(e => e[2]).reduce((a, b) => a.concat(b));
             } else {
 
                 if (selfOne.state.scrollStatus === 'next') {
                     selfOne.preEcgValues = selfOne.preEcgValues.filter(
                         (e, i) => (i > selfOne.state.limitScrollStart - 1)
                     );
 
                     selfOne.preEcgValues = selfOne.preEcgValues.concat(res.data)
 
                 } else if (selfOne.state.scrollStatus === 'prev') {
                     selfOne.preEcgValues = selfOne.preEcgValues.filter(
                         (e, i) => i < (selfOne.state.limitScrollStart - 1)
                     );
                     selfOne.preEcgValues = res.data.concat(selfOne.preEcgValues)
                 }
                 selfOne.ecgValues = selfOne.preEcgValues.map(e => e[0]).reduce((a, b) => a.concat(b));
                 selfOne.activityValues = selfOne.preEcgValues.map(e => e[1]).reduce((a, b) => a.concat(b));
                 selfOne.peakValues = selfOne.preEcgValues.map(e => e[2]).reduce((a, b) => a.concat(b));
 
             }
 
             /*console.log("preEcgValues",selfOne.preEcgValues);
             console.log("selfOne.ecgValues.length",selfOne.ecgValues.length);*/
             datasetMnt = Math.floor(selfOne.ecgValues.length / deviceFrequency)
             //console.log("datasetMnt",datasetMnt);
             if (selfOne.ecgValues.length % deviceFrequency > 0) {
                 datasetMnt += 1;
             }
 
             /*mainEcgArray = selfOne.ecgValues;
             mainActivityArray = selfOne.activityValues;*/
             selfOne.updateRpeaks(selfOne.peakValues);
             var graphPointIndex = 0;
             if (selfOne.state.scrollStatus === 'next') {
                 if (clickContainerIndex > 2) {
                     graphPointIndex = parseInt(clickContainerIndex) - 2;
                 }
             } else if (selfOne.state.scrollStatus === 'prev') {
                 graphPointIndex = 4;
                 if (clickContainerIndex < 2) {
                     graphPointIndex = parseInt(clickContainerIndex) + 2;
                 }
             }
 
             result3 = chunkPeakArray(selfOne.peakValues, selfOne.state.peakStart);
             window.result3 = result3;
             selfOne.setState({
                 result3: result3
             });
             
             var acti = 125;
             var counteri = 249;
             var counter = 0;
             var t = 0;
             var actarr = [];
             colorArray = ['#98FB98', '#f1e4e4', '#f7e0a3', '#f09c67']
 
 
             var sliceActStart = graphPointIndex * window.sc;
             var sliceActEnd = sliceActStart + window.sc + 1;
             var curDataArray = selfOne.activityValues.slice(sliceActStart, sliceActEnd);
             // console.log(selfOne.ecgValues.slice(sliceStart, sliceEnd));
 
 
             for (var i = 0; i < curDataArray.length; i++) {
                 actarr.push({
                     x: acti,
                     y: 0.5,
                     color: colorArray[curDataArray[i]]
                 });
                 acti = acti + 250;
             }
 
             chart.hideLoading();
             var sliceStart = graphPointIndex * deviceFrequency;
             var sliceEnd = sliceStart + deviceFrequency + 1;
             // console.log('ECG Valuess',selfOne.ecgValues.slice(sliceStart, sliceEnd));
             chart.series[0].setData(selfOne.ecgValues.slice(sliceStart, sliceEnd), true);
 
             chart.series[1].setData(actarr, true);
             chart.series[2].setData(result3[graphPointIndex], true);
 
             renderSmallCharts(graphPointIndex);
 
 
             selfOne.setState({
                 datasetMnt: datasetMnt
             });
             selfOne.setState({
                 mainEcgArray: selfOne.ecgValues
             });
             //console.log('mainOne', selfOne.state.mainEcgArray);
             selfOne.setState({
                 mainActivityArray: selfOne.activityValues
             });
             selfOne.setState({
                 result3: result3
             });
 
             selfOne.setState({
                 charts: charts
             });
             selfOne.setState({
                 chart: chart
             });
             selfOne.setState({
                 defarr: defarr
             });
             
             selfOne.setState({
                 starr: starr
             });
                     
                 });
                 
         var newArrAxiosRes2 = [];
         
         this.getData(userid, databaseid).then(value => {
        //console.log("value",value);
          for (var ij = 0; ij < window.sc-10; ij++) {
                     newArrAxiosRes2[ij] = value[ij].data;
                 }
                     if (newArrAxiosRes2 != undefined) {
             var ind = 0;
             for (var i = 10; i < window.sc; i++) {
                 if (window.mainArray[i].length == 0) {
                     window.mainArray[i].push(newArrAxiosRes2[ind][0]);
                     ind = ind + 1;
                 }
             }
             }
     });    

     var setGraphMarker = res => {
         this.state.graphMarker = res;

         var clickPointIndex = 0;
         if (this.state.scrollStatus === 'next') {
             if (clickContainerIndex > 2) {
                 clickPointIndex = parseInt(clickContainerIndex) - 2;
             }
         } else if (this.state.scrollStatus === 'prev') {
             clickPointIndex = 4;
             if (clickContainerIndex < 2) {
                 clickPointIndex = parseInt(clickContainerIndex) + 2;
             }
         }
         var graphPointIndex = pageIndex * 3;
     }

     if (Object.keys(this.state.graphMarker).length > 0) {
         setGraphMarker(this.state.graphMarker)
     } else {
         var markerThis=this;
         worker7.postMessage({ name:'getGraphMarker',url: apiUrl + 'api/common/getGraphMarker?userid=' + userid + '&databaseid=' + databaseid});
         worker7.onmessage = function (event) {};
         worker7.addEventListener("message", function (res) {
             if(res.data!=null){
             markerThis.state.graphMarker = res;

         var clickPointIndex = 0;
         if (markerThis.state.scrollStatus === 'next') {
             if (clickContainerIndex > 2) {
                 clickPointIndex = parseInt(clickContainerIndex) - 2;
             }
         } else if (markerThis.state.scrollStatus === 'prev') {
             clickPointIndex = 4;
             if (clickContainerIndex < 2) {
                 clickPointIndex = parseInt(clickContainerIndex) + 2;
             }
         }
         var graphPointIndex = pageIndex * 3;
     }
 
                 });
     }


        const data = {
            databaseid: dbId,
            userid: userid
        };

        var setVerifyCheck = res => {
            this.state.verifyCheck = res;
            if (res.data == 0) {
                $("#verfiyBtn").css("display", "inline-block");
            } else if (res.data == 1) {
                $("#verfiyBtn").css("display", "none");
            }
        }
        if (Object.keys(this.state.verifyCheck).length > 0) {
            setVerifyCheck(this.state.verifyCheck)
        } else {
            var verifycon=this;
            worker3.postMessage({ name:'checkVerify',url: apiUrl + 'api/common/checkVerify',dat: data});
            worker3.onmessage = function (event) {};
            worker3.addEventListener("message", function (res) {
                verifycon.state.verifyCheck = res;
                if (res.data == 0) {
                    $("#verfiyBtn").css("display", "inline-block");
                } else if (res.data == 1) {
                    $("#verfiyBtn").css("display", "none");
                }
                        
                    });
        }

    }

    nextBtnLink(userid, dbid) {
        axios
            .get(apiUrl + 'api/common/getNext?userid=' + userid + '&databaseid=' + dbid)
            .then(res => {
                if (res.data != null) {
                    document.getElementById("graphNext").style.display = 'inline-block';
                    this.setState({
                        nextDbId: res.data.ecgvalue_id
                    })
                } else {
                    document.getElementById("graphNext").style.display = 'none';
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    prevBtnLink(userid, dbid) {
        axios
            .get(apiUrl + 'api/common/getPrev?userid=' + userid + '&databaseid=' + dbid)
            .then(res => {
                if (res.data != null) {
                    document.getElementById("graphPrev").style.display = 'inline-block';
                    this.setState({
                        prevDbId: res.data.ecgvalue_id
                    })
                } else {
                    document.getElementById("graphPrev").style.display = 'none';
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    newDataClick(e) {
        var buttonId = e.currentTarget.id;
        var databaseid;
        var userid = this.props.match.params.userid;
        if (buttonId == 'nextBtn') {
            databaseid = this.state.nextDbId;
        } else if (buttonId == 'prevBtn') {
            databaseid = this.state.prevDbId;
        }
        document.getElementById("graphPrev").style.display = 'none';
        document.getElementById("graphNext").style.display = 'none';
        this.renderNewChart(userid, databaseid, 2);
        this.nextBtnLink(userid, databaseid);
        this.prevBtnLink(userid, databaseid);
    }


    componentDidMount() {
       
        
        window.container = 0;
        window.resumeFlag = 0;
        window.eventclass = 'all';
        window.selectAll = 0;
        window.beatArr = [];
        window.timer = null;
        window.chartid = 0;
        window.mainArray = [];
        window.prevStart=0;
        window.titleFlag=0;
        window.diaryFlag=0;
       
        //window.addEventListener('beforeunload', this.keepOnPage);

        this.callDataAPIs();
        if (!this.state.keyIntegrationStatus) {
            this.keyIntegration();
            this.state.keyIntegrationStatus = 1;
        }
    }

    keyIntegration() {
      
        $(document).keydown((e) => {
            //console.log('key down');

            if (this.state.currentTab == 'chart_tab_2' && this.state.keyIntegrationStatus!=0) {
                let chart = this.state.chart.xAxis[0]
                let extremes = chart.getExtremes()
                let zoomLevel = extremes.max - extremes.min
                switch (e.which) {
                    case 65:
                    case 37: // left
                        var minL = extremes.min - zoomLevel;
                        var maxL = extremes.min;
                        if (minL <= 0) {
                            maxL = 2500
                            minL = 0
                        }
                        if (extremes.min == 0) {
                            var e = $.Event("keydown");

                            // e.which is used to set the keycode
                            e.which = 38; // it is down
                            $("body").trigger(e);
                        } else {
                            chart.setExtremes(minL, maxL)
                        }
                        break;

                    case 87:
                    case 38: // up
                        //this.handleTopScroll();

                        break;

                    case 68:
                    case 39: // right
                        var min = extremes.max;
                        var max = extremes.max + zoomLevel;
                        var stripIndex = window.prevChart;
                        var sliceStart = stripIndex * deviceFrequency;
                        var sliceEnd = sliceStart + deviceFrequency + 1;
                        var currentEcgArray = this.state.mainEcgArray.slice(sliceStart, sliceEnd);
                        if ((max) >= deviceFrequency) {
                            max = deviceFrequency - 1
                            min = deviceFrequency - 2500
                        } else if ((max) > currentEcgArray.length) {
                            max = currentEcgArray.length;
                            min = currentEcgArray.length - zoomLevel;
                        }
                        if (extremes.max == deviceFrequency - 1) {
                            var e = $.Event("keydown");

                            // e.which is used to set the keycode
                            e.which = 40; // it is down
                            $("body").trigger(e);
                        } else {
                            chart.setExtremes(min, max)
                        }
                        break;

                    case 83:
                    case 40: // down   
                      //  this.handleBottomScroll();

                        break;

                    default:
                        return; // exit this handler for other keys
                }
                e.preventDefault(); // prevent the default action (scroll / move caret)
            }
        });
      
    }

        getDiaryInfo(e) {
           var userid = this.props.match.params.userid;
          axios
                        .get(apiUrl+'api/patients/getDiaryInfo?userid=' + userid )
                        .then(res => {
                           console.log(res.data);
                        })
                        .catch(err => {
                            console.log(err);
                        });

    }
    
    
    resumeVerify() {
        var userid = this.props.match.params.userid;
        window.resumeFlag = 1;
        axios
            .get(apiUrl + 'api/patients/getResumeDetails?userid=' + this.props.match.params.userid)
            .then(res => {
                console.log(res.data.stripvalue_resume);
                this.setState({
                    databaseid: res.data.db_resume
                });
                window.stripXValue = res.data.xvalue_resume - (res.data.stripvalue_resume * 15000);
                window.container = res.data.stripvalue_resume;
                $('#selWrap').scrollTop($('#container' + res.data.stripvalue_resume).position().top - ($('#selWrap').position().top));
            })
            .catch(err => {
                console.log(err);
            });
    }
    saveVerify() {
        var userid = this.props.match.params.userid;
        axios
            .get(apiUrl + 'api/patients/saveChartDetails?userid=' + this.props.match.params.userid)
            .then(res => {
                console.log(res.data.db_resume);
                this.setState({
                    databaseid: res.data.db_resume
                });
                this.setState({
                    start: res.data.stripvalue_resume
                });
                this.setState({
                    databaseid: res.data.stripvalue_resume + 9

                });
                this.renderNewChart(userid, res.data.db_resume, 1, this.state.start, this.state.end);      
            })
            .catch(err => {
                console.log(err);
            });
    }
    renderNewSmallCharts() {

        var j = this.state.start;
        var datasetMnt = j + 10;
        var start = this.state.start;
        var end = this.state.end;
        var userid = this.props.match.params.userid;

        for (var i = 0; i < this.state.charts.length; i++) {
            this.state.charts[i].destroy();
        }


        var i = 0;
        this.state.charts = [];
        var dis = this;
        var charts = [];
        console.log('datasetMnt',datasetMnt );
        var myVar = setInterval(function() {

            charts[i] = new Highcharts.Chart('container' + j, {
                credits: {
                    enabled: false
                },
                chart: {
                    height: 4 + '%',
                    resetZoomButton: {
                        theme: {
                            display: 'none'
                        },
                    },
                    events: {
                        click: function(e) {
                            /* Do something with the event */

                            clickFunction(e, this); // ...which means you can access it from inside the click handler function
                        }
                    },
                    zoomType: '',
                },

                navigator: {
                    enabled: false
                },

                xAxis: [{
                    min: 0,
                    max: deviceFrequency,
                    labels: {
                        enabled: false
                    },
                    visible: false

                }],
                yAxis: [{
                    min: gainMin,
                    max: gainMax,
                    gridLineWidth: 0,
                    minorGridLineWidth: 0,
                    minorTicks: false,
                    labels: {
                        enabled: false
                    },
                    title: {
                        enabled: false
                    },
                }],
                rangeSelector: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        turboThreshold: 0,
                    },
                },
                title: {
                    text: ''
                },

                series: [{
                    type: 'coloredline',
                    data: [],
                    color: '#09ff08',
                    allowPointSelect: false,
                    animation: false,
                    enableMouseTracking: false,
                    lineWidth: 2,
                    marker: {
                        enabled: false
                    },
                    shadow: false,
                    stickyTracking: false,
                    showInLegend: false
                }]
            });


            j = j + 1;
            i = i + 1;
            
            if (j > datasetMnt-1) {
                clearInterval(myVar);
            }
        }, 1);

        this.setState({
            charts: charts
        });


        var newThis = this,
            val;

        var databaseid = this.state.databaseid;
        var mainEcgArray, mainActivityArray = [];
        var result3, colorArray = [];
        var chart;
  
        var peakColorArray = [];
        peakColorArray['Normal Sinus Rhythm'] = '#09ff08';
        peakColorArray['Sinus Tachycardia'] = '#09ff08';
        peakColorArray['Sinus Bradycardia'] = '#09ff08';
        peakColorArray['PAC'] = '#09ff08';
        peakColorArray['PVC'] = '#09ff08';
        peakColorArray['Ventricular Tachycardia'] = '#09ff08';
        peakColorArray['AFib'] = '#9900FF';
        peakColorArray['Sinus Pause'] = '#09ff08';
        peakColorArray['P absent'] = '#09ff08';
        peakColorArray['Irregular RR'] = '#09ff08';
        peakColorArray['Irregular PR'] = '#09ff08';
        peakColorArray['Short QT'] = '#09ff08';
        peakColorArray['Prolonged QT'] = '#09ff08';
        peakColorArray['Undiagnosed'] = '#09ff08';



        function chunkPeakArray(peakArray, start = 0) {
            //console.log(peakArray);
            let presults = [];
            var minIndex = 0;
            var breakVal = (deviceFrequency * (window.pageIndex + 1));
            var z;
            var temRpeakindex;

            presults.push([]);
            for (var i = 0; i < peakArray.length; i++) {
                if (start * deviceFrequency < peakArray[i].rpeakindex) {
                    if (peakArray[i].rpeakindex > breakVal) {
                        presults.push([]);
                        minIndex = minIndex + 1;
                        breakVal = breakVal + deviceFrequency;
                    }
                    /*console.log(peakArray[i].rpeakindex);
                    console.log(minIndex);
                    console.log(peakArray[i].rpeakindex);
                    console.log(deviceFrequency);*/
                    temRpeakindex = parseInt(peakArray[i].rpeakindex) - ((minIndex + start) * deviceFrequency);
                    presults[minIndex].push({
                        x: temRpeakindex,
                        y: parseFloat(0.8),
                        color: peakColorArray[peakArray[i].arrhythmia],
                        beat: parseInt(peakArray[i].indexcounter),
                        hr: parseInt(peakArray[i].heartrate),
                        rr: parseInt(peakArray[i].rr_interval),
                        arrhythmia: peakArray[i].arrhythmia,
                        peaktime: peakArray[i].peaktime
                    });
                }
            }
            return presults;
        }

 
        function chunkMarkerArray(markArrayX, markArrayY, start = 0) {

            var ppeakIndex = markArrayX.split(',');
            var ppeakAmp = markArrayY.split(',');
            let mresults = [];
            var minIndex = 0;
            var breakVal = (start + 1) * deviceFrequency;
            var z;
            var temindex;
            mresults.push([]);
            for (var i = 0; i < ppeakIndex.length; i++) {
                if (ppeakIndex[i] > start * deviceFrequency) {
                    if (ppeakIndex[i] > breakVal) {
                        mresults.push([]);
                        minIndex = minIndex + 1;
                        breakVal = breakVal + deviceFrequency;
                    }
                    temindex = parseInt(ppeakIndex[i]) - ((minIndex + start) * deviceFrequency);
                    mresults[minIndex].push([temindex, parseFloat(ppeakAmp[i])]);
                }
            }
            return mresults;
        }

        function clickFunction(event, that) {
            console.log('prevChart', window.prevChart);
            var containerId = that.renderTo.id; //got the Container ID
            window.container = containerId.replace('container', '');
            window.chartid = parseInt(containerId.replace('container', '')) - newThis.state.start; //container id stored in global variable
            window.stripXValue = event.chartX; //got the location in the xaxis
           
            //console.log('DataIndex', window.dataArrInd);
            //var beats = JSON.parse(document.getElementById("beatSlected").value);
            //console.log(beatArr);
            /*************Remove Plotband***********************/

            if (window.beatArr.length > 0 && window.chartid != window.prevChart) {
                for (var i = 0; i < window.beatArr.length; i++) {
                    var j = parseInt(window.container)  * deviceFrequency;
                    var xBeat = parseInt(window.beatArr[i]) - j;

                    console.log('xBeat', xBeat);
                    newThis.state.chart.xAxis[0].removePlotBand('plotband-' + xBeat);
                }
                //document.getElementById("beatSlected").value='[]';
                // beatArr=[];
            }


            /*************Remove Plotband***********************/

            if (newThis.state.charts[window.chartid]) {
                val = newThis.state.charts[window.chartid].xAxis[0].toValue(event.chartX);

            }


            //console.log(selfNew.state.mainEcgArray);
            var sliceStart = window.chartid * deviceFrequency;
            var sliceEnd = sliceStart + deviceFrequency + 1;
            var sliceActStart = window.chartid * 60;
            var sliceActEnd = sliceActStart + 61;
            console.log('sliceStart',sliceStart);
            console.log('sliceStart',sliceEnd);
            var ecgArray = newThis.state.mainEcgArray.slice(sliceStart, sliceEnd);
            var curActArray = newThis.state.mainActivityArray.slice(sliceActStart, sliceActEnd);
 
          
            if (val > ecgArray.length) {
                val = ecgArray.length - 500;
            }

            var acti = 125;
            var actarr = [];
            var tttarr1 = [];
            //console.log('container',window.container);
            /*console.log(newThis.state.rarr[window.dataArrInd]);*/

            var colorArray = ['#98FB98', '#f1e4e4', '#f7e0a3', '#f09c67'];

            for (var i = 0; i < curActArray.length; i++) {
                actarr.push({
                    x: acti,
                    y: 0.5,
                    color: colorArray[curActArray[i]]
                });
                acti = acti + 250;
            }
            
            newThis.state.chart.series[0].setData(ecgArray, false);
            newThis.state.chart.series[1].setData(actarr, false);
           // newThis.state.chart.series[2].setData(newThis.state.result3[window.chartid], false);
            newThis.state.chart.series[2].setData(newThis.state.result3[0], false);

           console.log('Destroy CHart1',window.prevChart);

            if (newThis.state.charts[window.prevChart] != undefined) {
                if (newThis.state.charts[window.prevChart].draggablePlotLine != undefined) {
                    newThis.state.charts[window.prevChart].draggablePlotLine.destroy();
                }
            }
            var chart1 = that;

            window.prevChart = window.chartid;
            var lineWidth = 2


            chart1.draggablePlotLine = chart1.renderer.rect(event.chartX - newThis.state.charts[window.chartid].xAxis[0].toPixels(25), 10, 50, 40, 5)
         //  chart1.draggablePlotLine = chart1.renderer.rect(newThis.state.charts[0].xAxis[0].toPixels(strip_val) - newThis.state.charts[0].xAxis[0].toPixels(25), 10, 50, 40, 5)
                .attr({
                    'stroke-width': 2,
                    opacity: 0.5,
                    fill: 'grey',
                    zIndex: 3
                })
                .add();
            console.log("a i clickFunction");

            if (newThis.state.charts[0].container.onmousemove != undefined) {
                newThis.state.charts[0].container.onmousemove = function(e) {
                    return false;
                }
            }

            newThis.state.charts[window.chartid].container.onmousemove = function(e) {
                if (chart1.draggablePlotLine != undefined) {
                    if (chart1.draggablePlotLine.drag) {

                        let normalizedEvent = chart1.pointer.normalize(e),
                            extremes = {
                                left: chart1.plotLeft,
                                right: chart1.plotLeft + chart1.plotWidth
                            };

                        if (e.chartX >= extremes.left && e.chartX <= extremes.right) {
                            var pointValue = chart1.xAxis[0].toValue(e.chartX);
                            if (pointValue - 1250 <= 0) {
                                newThis.state.chart.xAxis[0].setExtremes(0, pointValue + 2500, true, false);
                            } else {
                                newThis.state.chart.xAxis[0].setExtremes(pointValue - 1250, pointValue + 1250, true, false);
                            }
                            chart1.draggablePlotLine.attr({
                                x: e.chartX
                            })
                        }
                    }
                }
            }

            newThis.state.charts[window.chartid].draggablePlotLine.element.onmousedown = function() {
                chart1.draggablePlotLine.drag = true;
            }

            newThis.state.charts[window.chartid].draggablePlotLine.element.onmouseup = function() {
                chart1.draggablePlotLine.drag = false;
            }
            if (val - 1250 <= 0) {
                newThis.state.chart.xAxis[0].setExtremes(0, val + 2500, true, false);
            } else {
                newThis.state.chart.xAxis[0].setExtremes(val - 1250, val + 1250);
            }
        }

        function setDataOnly(res, self) {

            var timeCounter = self.state.start;
            if (res != undefined) {
                var ind = 0;
                for (var i = 0; i < window.arrAxios.length; i++) {
                    if (window.mainArray[window.arrAxios[i]].length == 0) {
                        window.mainArray[window.arrAxios[i]].push(res[ind][0]);
                        ind = ind + 1;
                    }
                }
                res = [];
                for (var i = self.state.start; i <= self.state.end; i++) {
                    res.push(window.mainArray[i][0]);
                    kk = kk + 1;
                }

            } else {
                var kk = 0;
                var res = [];
                for (var i = self.state.start; i <= self.state.end; i++) {
                    //console.log('i value',i);
                    res.push(window.mainArray[i][0]);
                    kk = kk + 1;
                }
            }

            //console.log('FInal res',window.mainArray);

            if (!self.preEcgValues) {
                self.preEcgValues = res;
                self.ecgValues = res.map(e => e[0]).reduce((a, b) => a.concat(b));
                self.activityValues = res.map(e => e[1]).reduce((a, b) => a.concat(b));
                self.peakValues = res.map(e => e[2]).reduce((a, b) => a.concat(b));
            } else {

                if (self.state.scrollStatus === 'next') {
                    self.preEcgValues = self.preEcgValues.filter(
                        (e, i) => (i > self.state.limitScrollStart - 1)
                    );
                    //console.log("preEcgValues",self.preEcgValues.length);
                    self.preEcgValues = self.preEcgValues.concat(res)
                } else if (self.state.scrollStatus === 'prev') {
                    self.preEcgValues = self.preEcgValues.filter(
                        (e, i) => (i < self.state.limitScrollStart + 3)
                    );
                    // console.log("preValues",self.preEcgValues.length);
                    self.preEcgValues = res.concat(self.preEcgValues)
                } else {

                    self.preEcgValues = window.mainArray[timeCounter].filter(
                        (e, i) => (i < 0)
                    );

                    // console.log("preEcgValues",self.preEcgValues.length);
                    self.preEcgValues = self.preEcgValues.concat(res);
                    //console.log("preEcgValues",self.preEcgValues.length);
                    //console.log("preEcgValues",self.preEcgValues);
                }

                //console.log("preEcgValues",self.preEcgValues.length);
                self.ecgValues = self.preEcgValues.map(e => e[0]).reduce((a, b) => a.concat(b));
                self.activityValues = self.preEcgValues.map(e => e[1]).reduce((a, b) => a.concat(b));
                self.peakValues = self.preEcgValues.map(e => e[2]).reduce((a, b) => a.concat(b));

            }

            mainEcgArray = self.ecgValues;
            mainActivityArray = self.activityValues;

            self.setState({
                mainEcgArray: mainEcgArray
            });
            self.setState({
                mainActivityArray: mainActivityArray
            });

            result3 = chunkPeakArray(self.peakValues, self.state.start);

            self.setState({
                result3: result3
            })
           // console.log("state result",self.state.result3);
            window.result3 = result3;

            var starr = [],defarr = [];

            var i = 0;
            var t = 0;
            var j = 1;

            var stcolor = "#09ff08",defcolour = "#09ff08";

            var currentDataSet = [];
            var self = self;

            var beatInd = self.state.start;
            var myVar2 = setInterval(function() {

                var sliceStartI = i * deviceFrequency;
                var sliceEndI = sliceStartI + deviceFrequency + 1;
                currentDataSet = self.state.mainEcgArray.slice(sliceStartI, sliceEndI);

                var k = 0;
                /***********color***********/
                
                starr.push([]);
                defarr.push([]);
                for (var j = 0; j < currentDataSet.length; j++) {

                    if (self.state.result3[i][k] != null && (j + 70 == self.state.result3[i][k].x || self.state.result3[i][k].x < 70)) {

                        stcolor = "09ff08";
                        defcolour = "09ff08";

                        if (self.state.result3[i][k].arrhythmia == 'Normal Sinus Rhythm') {
                           
                            stcolor = self.state.result3[i][k].color;
                        } else if (self.state.result3[i][k].arrhythmia == 'Sinus Tachycardia') {
                            
                            stcolor = self.state.result3[i][k].color;
                        } else if (self.state.result3[i][k].arrhythmia == 'Sinus Bradycardia') {
                            
                            stcolor = self.state.result3[i][k].color;
                        } else if (self.state.result3[i][k].arrhythmia == 'Sinus Pause') {
                            
                            stcolor = self.state.result3[i][k].color;
                        } else if (self.state.result3[i][k].arrhythmia == 'PAC') {
                            
                            stcolor = self.state.result3[i][k].color;
                        } else if (self.state.result3[i][k].arrhythmia == 'PVC') {
                            
                            stcolor = self.state.result3[i][k].color;
                        } else if (self.state.result3[i][k].arrhythmia == 'Ventricular Tachycardia') {
                            
                            stcolor = self.state.result3[i][k].color;
                        } else if (self.state.result3[i][k].arrhythmia == 'AFib') {
                            
                            stcolor = self.state.result3[i][k].color;
                        } else if (self.state.result3[i][k].arrhythmia == 'P absent') {
                            
                            stcolor = self.state.result3[i][k].color;
                        } else if (self.state.result3[i][k].arrhythmia == 'Irregular RR') {
                            
                            stcolor = self.state.result3[i][k].color;
                        } else if (self.state.result3[i][k].arrhythmia == 'Irregular PR') {
                            
                            stcolor = self.state.result3[i][k].color;
                        } else if (self.state.result3[i][k].arrhythmia == 'Short QT') {
                            
                            stcolor = self.state.result3[i][k].color;
                        } else if (self.state.result3[i][k].arrhythmia == 'Prolonged QT') {
                            
                            stcolor = self.state.result3[i][k].color;
                        } else if (self.state.result3[i][k].arrhythmia == 'Undiagnosed') {
                            
                            stcolor = self.state.result3[i][k].color;
                        }

                        if (k < self.state.result3[i].length - 1) {
                            k++;
                        }
                    }
                    defarr[i].push({
                        x: j,
                        y: parseFloat(currentDataSet[j]),
                        segmentColor: '#09ff08'
                    });
                    starr[i].push({
                        x: j,
                        y: parseFloat(currentDataSet[j]),
                        segmentColor: stcolor
                    });
                    
                }

                /***********color**********/

                /**********setStateColor*********/
                
                self.setState({
                    starr: starr
                });
                
                self.setState({
                    defarr: defarr
                });

                /**********setStateColor*********/
                if (self.state.charts[i] != undefined) {
                    if (window.tab != 'Events') {

                            if (window.sel == 'Sinus Tachycardia') {
                            self.state.charts[i].series[0].setData(starr[i], true);
                        }
                        else
                        {
                            self.state.charts[i].series[0].setData(starr[i], true);
                        }
                        
                    } else {
                       if (window.response == 'Sinus Tachycardia') {
                            self.state.charts[i].series[0].setData(starr[i], true);
                        }
                        else
                        {
                            self.state.charts[i].series[0].setData(starr[i], true);
                        }   
                    }
                }
                if (window.mainArray[beatInd].length == 0) {
                    window.mainArray.splice(beatInd, 1, defarr[i]);
                }
                //console.log('MainArray',window.mainArray);
                i = i + 1;
                j = j + 1;
                beatInd = beatInd + 1;
                if (i > 9) {
                     if (window.prev_tab == 'Events') {
                            self.updateMainChartODC(self.state.start, self.state.end);
                           // window.prev_tab=window.tab;
                            window.tab = 'Beats';
                            window.prev_tab ='Beats';
                        }
                        if(self.props.match.params.start!=undefined && window.diaryFlag==0)
                            {
                              window.diaryFlag=1;
                              self.updateMainChartODC(self.props.match.params.start, self.props.match.params.end);                        
                            }
                    clearInterval(myVar2);
                }
            }, 1);
        }
        window.arrAxios = [];
        var count = 0
        for (var ii = start; ii <= end; ii++) {
            if (window.mainArray[ii].length == 0) {
                window.arrAxios.push(ii);
            }
        }
        var newArrAxios = [];

        if (window.arrAxios.length > 0) {

            var newArrAxiosRes = [];
            this.getRepos(userid, databaseid).then(value => {
                    for (var ij = 0; ij < window.arrAxios.length; ij++) {
                        newArrAxiosRes[ij] = value[ij].data;
                    }

                    setDataOnly(newArrAxiosRes, this);
                    if (window.resumeFlag == 1) {
                        this.renderResumeChart(userid, databaseid, window.container, window.stripXValue);
                        window.resumeFlag = 0;
                    }
                }
            );
        } else {
            setDataOnly(newArrAxios, this)
        }
    }


    async getRepos(userid, databaseid) {

        var ops = [];

        for (var ii = 0; ii < window.arrAxios.length; ii++) {
            let op = axios.get(apiUrl + 'api/common/getEcgNewData?start=' + window.arrAxios[ii] + '&end=' + window.arrAxios[ii] + '&userid=' + userid + '&databaseid=' + databaseid);
            ops.push(op);
        }

        let res = await axios.all(ops);

        return res;
    }
    async getData(userid, databaseid) {

        var ops = [];

        for (var ii = 10; ii < window.sc; ii++) {
            let op = axios.get(apiUrl + 'api/common/getEcgNewData?start=' + ii + '&end=' + ii + '&userid=' + userid + '&databaseid=' + databaseid);
            ops.push(op);
        }

        let res = await axios.all(ops);

        return res;
    }

    callDiaryDataAPIs(db,userid,start,end,stripX) {
        var userid = userid;
        var databaseid = db;
        
        this.setState({start:start,end:end});
        var stripThis=this;

        worker13.postMessage({ name:'getStripCount',url: apiUrl+'api/common/getStripCount?userid=' + userid + '&databaseid=' + databaseid});
        worker13.onmessage = function (event) {};
        worker13.addEventListener("message", function (res) {
                                window.sc = parseInt(res.data);
                                if(window.sc!=0)
                                {
                                for (var i = 0; i < window.sc; i++) {
                                    window.mainArray.push([]);
                                }
                                
                        if (typeof databaseid === 'undefined') {
                            databaseid = "0";
                            worker14.postMessage({ name:'getLatestDatabaseId',url: apiUrl + 'api/common/getLatestDatabaseId?userid=' + userid});
                            worker14.onmessage = function (event) {};
                            worker14.addEventListener("message", function (res) {
                                stripThis.setState({
                                    databaseid: res.data
                                });
                                stripThis.renderNewChart(userid, res.data, 1);
                                        
                                    });

                        } else {
                            stripThis.setState({
                                databaseid: databaseid
                            });

                            stripThis.renderNewChart(userid, databaseid, 1, stripThis.state.start, stripThis.state.end);

                        }
                    }                   
                });
 

       // this.checkFullVerify();
    }

    callDataAPIs() {
        var userid = this.props.match.params.userid;
        var databaseid = this.props.match.params.databaseid;
        var stripThis=this;
        worker13.postMessage({ name:'getStripCount',url: apiUrl+'api/common/getStripCount?userid=' + userid + '&databaseid=' + databaseid});
        worker13.onmessage = function (event) {};
        worker13.addEventListener("message", function (res) {
                                window.sc = parseInt(res.data);
                                if(window.sc!=0)
                                {
                                for (var i = 0; i < window.sc; i++) {
                                    window.mainArray.push([]);
                                }      
                        if (typeof databaseid === 'undefined') {
                            databaseid = "0";
                            worker14.postMessage({ name:'getLatestDatabaseId',url: apiUrl + 'api/common/getLatestDatabaseId?userid=' + userid});
                            worker14.onmessage = function (event) {};
                            worker14.addEventListener("message", function (res) {
                                stripThis.setState({
                                    databaseid: res.data
                                });
                                stripThis.renderNewChart(userid, res.data, 1);            
                                    });
                        } else {
                            stripThis.setState({
                                databaseid: databaseid
                            });

                            stripThis.renderNewChart(userid, databaseid, 1, stripThis.state.start, stripThis.state.end);

                        }
                    }                  
                });


        var setPatientReportStatus = res =>  {

            this.state.patientReportStatus = res;
            this.setState({
                reportStatus: res.data
            })
        }
        if (Object.keys(this.state.patientReportStatus).length > 0) {
            setPatientReportStatus(this.state.patientReportStatus)
        } else {

            axios
                .get(apiUrl + 'api/patients/patientReportStatus?userid=' + this.props.match.params.userid)
                .then(
                    setPatientReportStatus
                )
                .catch(err => {
                    console.log(err);
                });
        }

        var setPatientMenuTree = res => {
            console.log('ressss',res);
            this.state.patientMenuTree = res;
            this.setState({
                treemenu: res.data
            });
            console.log('treemenu',this.state.treemenu);
        }
        if (Object.keys(this.state.patientMenuTree).length > 0) {
            setPatientMenuTree(this.state.patientMenuTree)
        } else {
            var pmenu=this;
            worker6.postMessage({ name:'patinentmenutree',url: apiUrl + 'api/common/patinentmenutreeNew?userid=' + this.props.match.params.userid});
            worker6.onmessage = function (event) {};
            worker6.addEventListener("message", function (res) {
                pmenu.state.patientMenuTree = res;
            pmenu.setState({
                treemenu: res.data
            })           
                   });
        }

        if (this.state.reportLinkStatus == 0) {
            axios
                .get('https://web.mybiocalculus.com:5000/api/common/getReport?userid=' + this.props.match.params.userid)
                .then(res => {
                    this.state.reportLinkStatus = 1;
                    if (res.data != '') {
                        this.setState({
                            reoprtLink: res.data
                        })
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        }


        this.checkFullVerify();
    }

    disableKey(e) { 
    this.setState({keyIntegrationStatus:0});
    console.log("disableKey",this.state.keyIntegrationStatus)
  }
  enableKey(e) { 
    this.setState({keyIntegrationStatus:1})
  }


   handleModal(db) {
        console.log("db",db);
        this.setState({clickdb:db});
        this.setState({isModalOpen: true});
    }
     handleDiary(db,maindt) {
        console.log("db",db);
        console.log("userid",this.props.match.params.userid);
        console.log("maindt",maindt);
         var MainTime = db.split('T');
        var time = MainTime[1].split('+');
        db=MainTime[0]+" "+time[0];
        console.log(MainTime[0]+" "+time[0]);
        var searchArr=[];
        axios
            .get(apiUrl + 'api/common/getAllRPeakValues?userid=' + this.props.match.params.userid + '&databaseid=' + db)
            .then(res => {   
                searchArr.push(res.data);
                console.log("searchArr",searchArr);
                 var queryTime=maindt.split(" ")[1];
                 console.log("queryTime",queryTime);
                  
                  var closest= "23:59:59";
                  var rpeakI;
                  Object.keys(searchArr).map((val,key) => {
                      
                      searchArr[val].map((value,key)=>{
                        const time1 = value.peaktime;
                        
                     if (time1 >= queryTime && (time1 < closest || time1 < closest)) {
                        closest = value.peaktime;
                       
                        rpeakI=value.rpeakindex;
                         console.log('key',key);
                         console.log("closest",closest);
                         console.log("rpeakI",rpeakI);
                              //this.callDiaryDataAPIs(db,this.props.match.params.userid,start,end,window.stripXValue);
                     }
                      });   
                });
                  if (rpeakI > 15000) {
                          var stripIndex = Math.floor(rpeakI / 15000);
                      } else {
                          var stripIndex = 0;
                      }
                          window.stripXValue = parseInt(rpeakI) - (stripIndex * 15000);
                          var start = stripIndex,
                              end = stripIndex + 9;
                              //console.log("rpeakindex",rpeakI);
      
                              if(Number.isNaN(window.stripXValue))
                              {
                                console.log("window.stripXValue",window.stripXValue);
                               alert("dataset not available")                             
                            }
                            else
                            {
                              window.location.href="/verifier-chart/"+this.props.match.params.userid+"/"+db+"/"+start+"/"+end+"/"+window.stripXValue;
                            }
            })
            .catch(err => {
                console.log(err);
            });

    }
   onChange = e => {

    this.setState({ [e.target.name]: e.target.value });
    //console.log(e.target.name)
    if(e.target.name=='arr_list')
    {    
        this.setState({ ['newName']: e.target.value  });
        
    }
   
  }
    render() {    
      //selWrap
      let titleFlag=0;

        const { errors} = this.state;
      //const defaultOption = options[0];
      if(localStorage.jwtToken==undefined) { window.location.href = '/'; }
      const userid = this.props.match.params.userid; 
      var databaseid = this.props.match.params.databaseid;
      if (typeof databaseid === 'undefined'){
        databaseid="0";
      }
      return (
      <div className="wrapper theme-1-active box-layout pimary-color-green">
        <input type="hidden" value={this.state.nextDbId} id="nextDbId" />
        <input type="hidden" value={this.state.prevDbId} id="prevDbId" />
        <TopNav profileLink={this.state.profileLink} userid={userid} databaseid={databaseid}  />
        <SideBarGraph userid={userid} onSelectModal={this.handleModal}  menus={this.state.menus} treemenu={this.state.treemenu} databaseid={databaseid} />
         <AddModal userid={userid} isOpen={this.state.isModalOpen} clickdb={this.state.clickdb} start={this.props.match.params.start}/>
        <div className="right-sidebar-backdrop"></div>
        <div className="page-wrapper" style={{ marginTop: "3%"}}>
          <div className="container-fluid" style={{ marginTop: "3%"}}>
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div className="panel panel-default card-view graphMainCont" style={{marginTop:'-1%'}}>
                  <div className="panel-wrapper collapse in">
                    <div className="panel-body">
                      <div  className="pills-struct">
                        <ul role="tablist" className="nav nav-pills" id="myTabs_6">
                          <li  role="presentation" style={{"height":"30px"}} className='active' >
                            <a  data-toggle="tab" id="chart_tab_2" onClick={this.setTab.bind(this)} role="tab" href="#chart_beats" aria-expanded="true" style={{"height":"30px"}}>{this.props.match.params.start==undefined && <img id="beatsimg" src="../../dist/img/Beats.png"/>}{this.props.match.params.start!=undefined && <img id="beatsimg" src="../../../../dist/img/Beats.png"/>}</a>
                          </li>   
                           <li role="presentation"  style={{"height":"30px"}}>
                            <a  data-toggle="tab" id="chart_tab_findings" onClick={this.setTab.bind(this)} role="tab" href="#chart_findings" aria-expanded="false" style={{"height":"30px"}}><img id="addFindImg" src="../../dist/img/AddFindings.png"/></a>
                          </li>
           
                            <li role="presentation" id="chart_tab_report" className="pull-right">
                              <a  href={`https://pdf.mybiocalculus.com/?userid=${userid}`}>Generate Report</a>
                            </li>  

                          { this.state.reportStatus ==3 &&
                            <li role="presentation" id="chart_tab_report" className="pull-right">
                              <a href="#">Please Wait..</a>
                            </li>  
                          }   
                          { this.state.reportStatus >=4 &&
                            
                              <a className="pull-right" onClick={this.mailReport.bind()} id="chart_tab_email" href="#" style={{"height":"30px"}}>{this.props.match.params.start==undefined && <img id="emailImg" src="../../dist/img/Email.png"/>}{this.props.match.params.start!=undefined && <img id="emailImg" src="../../../../../dist/img/Email.png"/>}</a>
                            
                          }
                          { this.state.reportStatus >=4 &&
                            
                              <a  className="pull-right" target="_blank" href={this.state.reoprtLink} id="chart_tab_reportView"  style={{"height":"30px"}}>{this.props.match.params.start==undefined && <img id="reportImg" src="../../dist/img/Report.png"/>}{this.props.match.params.start!=undefined && <img id="reportImg" src="../../../../../dist/img/Report.png"/>}</a>   
                          }
                       
                            <a  className="pull-right" data-toggle="tab" id="chart_tab_8" onClick={this.setTab.bind(this)} role="tab" href="#diary_findings" aria-expanded="false" style={{"height":"30px"}}>{this.props.match.params.start==undefined && <img id="addDiaryImg" src="../../dist/img/diaryNotes.png"/>}{this.props.match.params.start!=undefined && <img id="addDiaryImg" src="../../../../../dist/img/diaryNotes.png"/>}</a>
                                
                        </ul>
                        <div className="clearfix"></div>
                        <div className="tab-content" id="myTabContent_6">
                        <div  id="chart_events" className="tab-pane fade in" role="tabpanel">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="">
                                <div className="panel-wrapper collapse in">
                                  <div className="panel-body">
                                    <h5 className="panel-title txt-dark cd">Cardiac Event Detected</h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> 
                        </div>

                          <div  id="chart_beats" className="tab-pane fade active in" role="tabpanel">
                            <div className="beat" id="beat">
                              <div className="panel-body">
                                <div  className="row">
                                  <div className="col-md-4"><div className="displayNone" id="graphPrev"><button id="prevBtn" className="arythmia-btn" onClick={this.newDataClick.bind(this)}>Previous</button></div></div>
                                  <div className="col-md-4 text-center">Database Id :: {this.state.databaseid}</div>
                                  <div className="col-md-4 text-right"><div className="displayNone" id="graphNext"><button id="nextBtn" className="arythmia-btn" onClick={this.newDataClick.bind(this)}>Next</button></div></div>
                                </div>
                                <div id="tt_container"></div>
                                <div className="text-right">
                                   
                                  <select id="gainValue" style= {{color: 'Black'}} onChange={this.gainBtn.bind(this)}>
                                    <option value="1" style= {{color: 'Black'}} >1X</option>
                                    <option value="2" style= {{color: 'Black'}} >2X</option>
                                    <option value="3" style= {{color: 'Black'}} >3X</option>
                                  </select>  
                                </div>
                                <input type="hidden" name="beatSlected" id="beatSlected" value="[]"/>
                                <br/>
                               
                                  <div id="selWrap" className="sel" onScroll={this.listenScrollEvent.bind(this)}></div>
   
                                {/* <input type="hidden" name="selectedChart" id="selectedChart" value={this.state.selectedChart}/>           */}
                              </div>
                            </div>  
                          </div>
 

                          {/* <div  id="chart_rate" className="tab-pane fade" role="tabpanel">
                          <HeartRateTab userid={userid}/>
                        </div> */}
                        {/* <div  id="chart_sel_events" className="tab-pane fade" role="tabpanel">
                            <VerifierSelectedBeats userid={userid} databaseid={databaseid} datasets={this.state.datasets} imagess={this.state.imagess} select={window.eventclass}/>
                          </div> */}
                       
                          {/* <div  id="chart_settings" className="tab-pane fade" role="tabpanel">
                            <SettingsTab userid={userid} reportStatus={this.state.reportStatus}/>
                          </div> */}
                          
                          <div  id="chart_findings" className="tab-pane fade" role="tabpanel">
                            <AddDoctorFindingsTab userid={userid} databaseid={databaseid} reportStatus={this.state.reportStatus}/>
                          </div>
                          <div  id="diary_findings" className="tab-pane fade" role="tabpanel">
                            <DiaryNotes userid={userid} databaseid={databaseid} treemenu={this.state.treemenu} onSelectDiary={this.handleDiary}/>
                          </div>

                          <div  id="chart_regenerate" className="tab-pane fade" role="tabpanel">
                            <p>Regenerate</p>
                          </div>
                          <div  id="chart_report" className="tab-pane fade" role="tabpanel">
                            <p>Report</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> 
            </div>       
          </div>
        </div>
      </div>

      )
    }
}

VerifierChart.propTypes = {
    
    auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    auth: state.auth
})

export default connect(mapStateToProps)(VerifierChart);