import React, {Component} from 'react';
import axios from 'axios';
import apiUrl from '../../config/config';
import classnames from 'classnames';
import $ from "jquery";
import ProgressBtn from '../../components/common/ProgressButtonDoctor';
import { MDBDataTable } from 'mdbreact';
import socketIOClient from "socket.io-client";
import TopNav from '../../components/common/TopNav';
import SideBar from '../../components/common/SideBar';
// import Worker from "worker-loader!./worker.js";
const socket = socketIOClient('https://web.mybiocalculus.com:5000');
class DoctorHome extends Component {
  constructor() {
    super();
    this.state = {
        menus : [
            {title:'Patient List',url:'/doctor-home'},
        ],
        profileLink : 'doctor-profile',
        patients : [],
        nor_minhr:"",
        nor_maxhr:"",
        qtcformula:"",
        qtcvalue_min:"",
        qtcvaluemax:"",
        qrswidth_min:"",
        qrswidth_max:"",
        qrsamp_min:"",
        qrsamp_max:"",
        stelevation:"",
        pwidth_min:"",
        pwidth_max:"",
        pamp_min:"",
        pamp_max:"",
        stdepression:"",
        twidth_min:"",
        twidth_max:"",
        tamp_min:"",
        tamp_max:"",
        printerval_min:"",
        printerval_max:"",
        tachyhr_lower:"",
        tachyhr_upper:"",
        bradyhr_lower:"",
        bradyhr_upper:"",
        pauselength:"",
        graph_gen:"",
        errors: {},
        userid:'',
        data:{},
        socketNew:socket
    };
    this.selectItem = this.selectItem.bind(this);
  }

  componentWillReceiveProps(nextProps) { 
  	
  }

  componentDidMount(){

   // const worker = new Worker();
    var newstat=this;
    axios
      .get(apiUrl+'api/patients/patients')
      .then(res => { 
        console.log(res.data);        
        this.setState({
          patients: res.data
        });
        const data = {
          columns: [
            {
              label: 'SL NO',
              field: 'slno',
              sort: 'asc',
              width: 50
            },
            {
              label: 'PATIENT ID',
              field: 'patientId',
              sort: 'asc',
              width: 100
            },
            {
              label: 'ADDED DATE',
              field: 'added_date',
              sort: 'asc',
              width: 100
            },
            {
              label: 'NAME',
              field: 'name',
              sort: 'asc',
              width: 150
            },
            {
              label: 'Action',
              field: 'action',
              sort: 'disabled',
              width: 200
            },
            // {
            //   label: 'REPORT',
            //   field: 'report',
            //   sort: 'disabled',
            //   width: 150
            // }
          ],
          rows: []
        };
        var onClick = this.selectItem.bind(this);
        var retrieve = this.retrieve.bind(this);
        var createdData = "";
        this.state.patients.map(function(patient,i) {
          var url=" ";
           if(patient.LastDatasetId!=undefined)
              {

                 if(patient.backup_status==0 || patient.backup_status==undefined)
                 {
                
                  // url = "/doctor-unanalysed/"+patient._id+"/"+patient.LastDatasetId;
                  url = " ";
                  
                   
                  }
                  else
                  {
                      url = " ";
                     

                  }
            }
          
          var anl_btn_txt = 'Not Analyzed';
          var anl_btn_class = 'btn-success';
          var gen_btn_txt = 'Not  Generated';
          var gen_btn_class = 'btn-danger';
          var report_link = "#";
          var report_link_target = "";
          if(patient.report_status >=2) {
           
             if(patient.backup_status==0 || patient.backup_status==undefined)
                 {
                  // url = "/main/"+patient._id+"/"+patient.LastDatasetId;
                  url = " ";
                  
                  // console.log('url',url,patient.name)
                  }
                  else
                  {
                      url = " ";
                     

                  }
            anl_btn_txt = 'Analyzed';
            anl_btn_class = 'btn-success';
          }
          if(patient.report_status >=4) {
            gen_btn_txt = 'View Report';
            gen_btn_class = 'btn-success';
            report_link_target = "_blank";
                report_link = "https://web.mybiocalculus.com/assets/dist/user_records/"+patient._id+"/Report"+patient._id+".pdf";
          }
          if(patient.backup_status==0||patient.backup_status==undefined) {
                  anl_btn_txt = 'Analyzed';
                }
                else if(patient.backup_status==1){
                   anl_btn_txt = 'Retrieve';
                }
                 else if(patient.backup_status==2){
                   anl_btn_txt = 'Retrieving';
                }
          createdData = patient.created_at.split('T');
          data.rows.push({
            slno: i+1,
            patientId: patient.device_id,
            added_date: createdData[0],
            name:patient.name,
            action: <a href="#">
                       <ProgressBtn id={patient._id}  bc_stat={patient.backup_status }  socket={newstat.state.socketNew}/>
                      <button type="button" id={patient._id} onClick={onClick} className="btn btn-primary" data-toggle="modal" data-target="#myModalDHome">Setting</button>
                    </a>,
            report: <React.Fragment>  <input type="hidden" className="patientLink" value={url} />
                      {/* <a href={report_link} target={report_link_target}>
                        <button type="button" className={"btn "+gen_btn_class}>{gen_btn_txt}</button>
                      </a>
                     */}
                    </React.Fragment>
          });
        })
        this.setState({data:data});
      })
      .catch(err => {
      });

    $( document ).ready(function() {
        $("#mbdTable").on('click', 'tr td:nth-child(2)', function () {
          var url = $( this ).parent().find(".patientLink").val();
           if(url!=" ")
          {
          window.location.href = url;
        }
        });
        $("#mbdTable").on('click', 'tr td:nth-child(3)', function () {

          var url = $( this ).parent().find(".patientLink").val();
           if(url!=" ")
          {
          window.location.href = url;
        }
        });
        $("#mbdTable").on('click', 'tr td:nth-child(4)', function () {
          var url = $( this ).parent().find(".patientLink").val();
           if(url!=" ")
          {
          window.location.href = url;
        }
        });
    });
  }

  onSubmit = e => {
    e.preventDefault();

    const settings = {
      userid:this.state.userid,
      nor_minhr: this.state.nor_minhr,
      nor_maxhr: this.state.nor_maxhr,
      qtcformula: this.state.qtcformula,
      qtcvalue_min: this.state.qtcvalue_min,
      qtcvaluemax: this.state.qtcvaluemax,
      qrswidth_min: this.state.qrswidth_min,
      qrswidth_max: this.state.qrswidth_max,
      qrsamp_min: this.state.qrsamp_min,
      qrsamp_max: this.state.qrsamp_max,
      stelevation: this.state.stelevation,
      pwidth_min: this.state.pwidth_min,
      pwidth_max: this.state.pwidth_max,
      pamp_min: this.state.pamp_min,
      pamp_max: this.state.pamp_max,
      stdepression: this.state.stdepression,
      twidth_min: this.state.twidth_min,
      twidth_max: this.state.twidth_max,
      tamp_min: this.state.tamp_min,
      tamp_max: this.state.tamp_max,
      printerval_min: this.state.printerval_min,
      printerval_max: this.state.printerval_max,
      tachyhr_lower: this.state.tachyhr_lower,
      tachyhr_upper: this.state.tachyhr_upper,
      bradyhr_lower: this.state.bradyhr_lower,
      bradyhr_upper: this.state.bradyhr_upper,
      pauselength: this.state.pauselength,
      graph_gen: this.state.graph_gen,
    };

    console.log(settings);
    axios
      .post(apiUrl+'api/patients/updateSettings',settings)
      .then(res => {
        $( "#closePopup" ).click();
      })
      .catch(err => {
          console.log(err); 
      });
  }

  onChange = e => {
    var err = this.state.errors;
    delete err[e.target.name];
    this.setState({ [e.target.name]: e.target.value });
  }
  retrieve(e)
  {
                var userid = e.currentTarget.id;
                if(e.currentTarget.innerHTML=="Retrieve")
                {
                if (window.confirm('Do you want to retrieve the data from backup?')) {
             axios
                .get('https://web.mybiocalculus.com/html/Welcome/backupData/' + userid)
                .then(res => {
                    if (res.data != null) {
                        console.log(res.data);
                    } else {
                        console.log('nothing');
                    }
                })
                .catch(err => {
                    console.log(err);
                });
                

                 }
               }
               else
               {

               }
            
    
    
  }
  selectItem(e){
    var userid = e.currentTarget.id;
    this.setState({ userid: userid });
    this.setState({
        nor_minhr:"",
        nor_maxhr:"",
        qtcformula:"",
        qtcvalue_min:"",
        qtcvaluemax:"",
        qrswidth_min:"",
        qrswidth_max:"",
        qrsamp_min:"",
        qrsamp_max:"",
        stelevation:"",
        pwidth_min:"",
        pwidth_max:"",
        pamp_min:"",
        pamp_max:"",
        stdepression:"",
        twidth_min:"",
        twidth_max:"",
        tamp_min:"",
        tamp_max:"",
        printerval_min:"",
        printerval_max:"",
        tachyhr_lower:"",
        tachyhr_upper:"",
        bradyhr_lower:"",
        bradyhr_upper:"",
        pauselength:"",
        graph_gen:"",
    });
    axios
      .get(apiUrl+'api/patients/settings?userid='+userid)
      .then(res => {
        var data = res.data;
        this.setState(data);

      })
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    const { errors } = this.state;
    return (
        <div className="wrapper theme-1-active box-layout pimary-color-green">
        <TopNav profileLink={this.state.profileLink}/>
        <SideBar menus={this.state.menus} />
        <div className="right-sidebar-backdrop"></div>
        <div className="page-wrapper">
            <div className="container-fluid">
                <div id="myModalDHome" className="modal fade" role="dialog">
                  <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                            <h4 className="modal-title text-center">General Settings</h4>
                        </div>
                        <form method="post"  onSubmit={this.onSubmit}>
                            <div className="modal-body">
                                <div className="row row-sm-offset">
                                     <br/>
                                     <div className="col-md-4 multi-horizontal">
                                        <div className="form-group">
                                           <label className="form-control-label mbr-fonts-style display-7" >Normal Heart Rate Min</label>
                                           <input 
                                           type="text" 
                                           className={classnames('form-control',{
                                           'errors' : errors.nor_minhr
                                           })} 
                                           name="nor_minhr" 
                                           required="" 
                                           id="nor_minhr" 
                                           onChange={this.onChange}
                                           value={this.state.nor_minhr} 
                                           />
                                        </div>
                                     </div>
                                     <div className="col-md-4 multi-horizontal">
                                        <div className="form-group">
                                           <label className="form-control-label mbr-fonts-style display-7" >Normal Heart Rate Max</label>
                                           <input 
                                           type="text" 
                                           className={classnames('form-control',{
                                           'errors' : errors.nor_maxhr
                                           })} 
                                           name="nor_maxhr" 
                                           required="" 
                                           id="nor_maxhr" 
                                           onChange={this.onChange}
                                           value={this.state.nor_maxhr} 
                                           />
                                        </div>
                                     </div>
                                     <div className="col-md-4 multi-horizontal">
                                        <div className="form-group">
                                           <label className="form-control-label mbr-fonts-style display-7" >QTC Formula</label>
                                           <select 
                                           name="qtcformula" 
                                           className={classnames('form-control',{
                                           'errors' : errors.qtcformula
                                           })} 
                                           value={this.state.qtcformula}
                                           onChange={this.onChange}
                                           >
                                           <option value="Bazett">Bazett </option>
                                           <option value="Fridericia">Fridericia</option>
                                           <option value="Framingham">Framingham</option>
                                           <option value="Hodges">Hodges</option>
                                           </select>
                                        </div>
                                     </div>
                                  </div>
                                  <div className="row row-sm-offset">
                                     <br/>
                                     <div className="col-md-4 multi-horizontal">
                                        <div className="form-group">
                                           <label className="form-control-label mbr-fonts-style display-7" >QTC Value Min</label>
                                           <input 
                                           type="text" 
                                           className={classnames('form-control',{
                                           'errors' : errors.qtcvalue_min
                                           })} 
                                           name="qtcvalue_min" 
                                           required="" 
                                           id="qtcvalue_min" 
                                           onChange={this.onChange}
                                           value={this.state.qtcvalue_min} 
                                           />
                                        </div>
                                     </div>
                                     <div className="col-md-4 multi-horizontal">
                                        <div className="form-group">
                                           <label className="form-control-label mbr-fonts-style display-7" >QTC Value Max</label>
                                           <input 
                                           type="text" 
                                           className={classnames('form-control',{
                                           'errors' : errors.qtcvaluemax
                                           })} 
                                           name="qtcvaluemax" 
                                           required="" 
                                           id="qtcvaluemax" 
                                           onChange={this.onChange}
                                           value={this.state.qtcvaluemax} 
                                           />
                                        </div>
                                     </div>
                                     <div className="col-md-4 multi-horizontal">
                                        <div className="form-group">
                                           <label className="form-control-label mbr-fonts-style display-7" >QRS Width Min(ms) </label>
                                           <input 
                                           type="text" 
                                           className={classnames('form-control',{
                                           'errors' : errors.qrswidth_min
                                           })} 
                                           name="qrswidth_min" 
                                           required="" 
                                           id="qrswidth_min" 
                                           onChange={this.onChange}
                                           value={this.state.qrswidth_min} 
                                           />
                                        </div>
                                     </div>
                                  </div>
                                  <div className="row row-sm-offset">
                                     <div className="col-md-4 multi-horizontal">
                                        <div className="form-group">
                                           <label className="form-control-label mbr-fonts-style display-7" >QRS Width Max (ms) </label>
                                           <input 
                                           type="text" 
                                           className={classnames('form-control',{
                                           'errors' : errors.qrswidth_max
                                           })} 
                                           name="qrswidth_max" 
                                           required="" 
                                           id="qrswidth_max" 
                                           onChange={this.onChange}
                                           value={this.state.qrswidth_max} 
                                           />
                                        </div>
                                     </div>
                                     <div className="col-md-4 multi-horizontal">
                                        <div className="form-group">
                                           <label className="form-control-label mbr-fonts-style display-7">QRS Amplitude Min (mv)</label>
                                           <input 
                                           type="text" 
                                           className={classnames('form-control',{
                                           'errors' : errors.qrsamp_min
                                           })} 
                                           name="qrsamp_min" 
                                           required="" 
                                           id="qrsamp_min" 
                                           onChange={this.onChange}
                                           value={this.state.qrsamp_min} 
                                           />
                                        </div>
                                     </div>
                                     <div className="col-md-4 multi-horizontal">
                                        <div className="form-group">
                                           <label className="form-control-label mbr-fonts-style display-7">QRS Amplitude Max (mv)</label>
                                           <input 
                                           type="text" 
                                           className={classnames('form-control',{
                                           'errors' : errors.qrsamp_max
                                           })} 
                                           name="qrsamp_max" 
                                           required="" 
                                           id="qrsamp_max" 
                                           onChange={this.onChange}
                                           value={this.state.qrsamp_max} 
                                           />
                                        </div>
                                     </div>
                                  </div>
                                  <div className="row row-sm-offset">
                                     <div className="col-md-4 multi-horizontal">
                                        <div className="form-group">
                                           <label className="form-control-label mbr-fonts-style display-7" >ST Elevation (mm)</label>
                                           <input 
                                           type="text" 
                                           className={classnames('form-control',{
                                           'errors' : errors.stelevation
                                           })} 
                                           name="stelevation" 
                                           required="" 
                                           id="stelevation" 
                                           onChange={this.onChange}
                                           value={this.state.stelevation} 
                                           />
                                        </div>
                                     </div>
                                     <div className="col-md-4 multi-horizontal">
                                        <div className="form-group">
                                           <label className="form-control-label mbr-fonts-style display-7" >P Width Min (ms)</label>
                                           <input 
                                           type="text" 
                                           className={classnames('form-control',{
                                           'errors' : errors.pwidth_min
                                           })} 
                                           name="pwidth_min" 
                                           required="" 
                                           id="pwidth_min" 
                                           onChange={this.onChange}
                                           value={this.state.pwidth_min} 
                                           />
                                        </div>
                                     </div>
                                     <div className="col-md-4 multi-horizontal">
                                        <div className="form-group">
                                           <label className="form-control-label mbr-fonts-style display-7">P Width Max (ms)</label>
                                           <input 
                                           type="text" 
                                           className={classnames('form-control',{
                                           'errors' : errors.pwidth_max
                                           })} 
                                           name="pwidth_max" 
                                           required="" 
                                           id="pwidth_max" 
                                           onChange={this.onChange}
                                           value={this.state.pwidth_max} 
                                           />
                                        </div>
                                     </div>
                                  </div>
                                  <div className="row row-sm-offset">
                                     <div className="col-md-4 multi-horizontal">
                                        <div className="form-group">
                                           <label className="form-control-label mbr-fonts-style display-7" >P Amplitude Min (mv)</label>
                                           <input 
                                           type="text" 
                                           className={classnames('form-control',{
                                           'errors' : errors.pamp_min
                                           })} 
                                           name="pamp_min" 
                                           required="" 
                                           id="pamp_min" 
                                           onChange={this.onChange}
                                           value={this.state.pamp_min} 
                                           />
                                        </div>
                                     </div>
                                     <div className="col-md-4 multi-horizontal">
                                        <div className="form-group">
                                           <label className="form-control-label mbr-fonts-style display-7" >P Amplitude Max (mv)</label>
                                           <input 
                                           type="text" 
                                           className={classnames('form-control',{
                                           'errors' : errors.pamp_max
                                           })} 
                                           name="pamp_max" 
                                           required="" 
                                           id="pamp_max" 
                                           onChange={this.onChange}
                                           value={this.state.pamp_max} 
                                           />
                                        </div>
                                     </div>
                                     <div className="col-md-4 multi-horizontal">
                                        <div className="form-group">
                                           <label className="form-control-label mbr-fonts-style display-7" >T Width Min (ms) </label>
                                           <input 
                                           type="text" 
                                           className={classnames('form-control',{
                                           'errors' : errors.twidth_min
                                           })} 
                                           name="twidth_min" 
                                           required="" 
                                           id="twidth_min" 
                                           onChange={this.onChange}
                                           value={this.state.twidth_min} 
                                           />
                                        </div>
                                     </div>
                                  </div>
                                  <div className="row row-sm-offset">
                                     <div className="col-md-4 multi-horizontal">
                                        <div className="form-group">
                                           <label className="form-control-label mbr-fonts-style display-7">T Width Max (ms)</label>
                                           <input 
                                           type="text" 
                                           className={classnames('form-control',{
                                           'errors' : errors.twidth_max
                                           })} 
                                           name="twidth_max" 
                                           required="" 
                                           id="twidth_max" 
                                           onChange={this.onChange}
                                           value={this.state.twidth_max} 
                                           />
                                        </div>
                                     </div>
                                     <div className="col-md-4 multi-horizontal">
                                        <div className="form-group">
                                           <label className="form-control-label mbr-fonts-style display-7" >T Amplitude Min  (mv)</label>
                                           <input 
                                           type="text" 
                                           className={classnames('form-control',{
                                           'errors' : errors.tamp_min
                                           })} 
                                           name="tamp_min" 
                                           required="" 
                                           id="tamp_min" 
                                           onChange={this.onChange}
                                           value={this.state.tamp_min} 
                                           />
                                        </div>
                                     </div>
                                     <div className="col-md-4 multi-horizontal">
                                        <div className="form-group">
                                           <label className="form-control-label mbr-fonts-style display-7" >T Amplitude Max (mv)</label>
                                           <input 
                                           type="text" 
                                           className={classnames('form-control',{
                                           'errors' : errors.tamp_max
                                           })} 
                                           name="tamp_max" 
                                           required="" 
                                           id="tamp_max" 
                                           onChange={this.onChange}
                                           value={this.state.tamp_max} 
                                           />
                                        </div>
                                     </div>
                                  </div>
                                  <div className="row row-sm-offset">
                                     <div className="col-md-4 multi-horizontal">
                                        <div className="form-group">
                                           <label className="form-control-label mbr-fonts-style display-7" >PR Interval  Min </label>
                                           <input 
                                           type="text" 
                                           className={classnames('form-control',{
                                           'errors' : errors.printerval_min
                                           })} 
                                           name="printerval_min" 
                                           required="" 
                                           id="printerval_min" 
                                           onChange={this.onChange}
                                           value={this.state.printerval_min} 
                                           />
                                        </div>
                                     </div>
                                     <div className="col-md-4 multi-horizontal">
                                        <div className="form-group">
                                           <label className="form-control-label mbr-fonts-style display-7" >PR Interval  Max </label>
                                           <input 
                                           type="text" 
                                           className={classnames('form-control',{
                                           'errors' : errors.printerval_max
                                           })} 
                                           name="printerval_max" 
                                           required="" 
                                           id="printerval_max" 
                                           onChange={this.onChange}
                                           value={this.state.printerval_max} 
                                           />
                                        </div>
                                     </div>
                                     <div className="col-md-4 multi-horizontal">
                                        <div className="form-group">
                                           <label className="form-control-label mbr-fonts-style display-7">Tachy Lower Limit </label>
                                           <input 
                                           type="text" 
                                           className={classnames('form-control',{
                                           'errors' : errors.tachyhr_lower
                                           })} 
                                           name="tachyhr_lower" 
                                           required="" 
                                           id="tachyhr_lower" 
                                           onChange={this.onChange}
                                           value={this.state.tachyhr_lower} 
                                           />
                                        </div>
                                     </div>
                                  </div>
                                  <div className="row row-sm-offset">
                                     <div className="col-md-4 multi-horizontal">
                                        <div className="form-group">
                                           <label className="form-control-label mbr-fonts-style display-7">Tachy Upper Limit  </label>
                                           <input 
                                           type="text" 
                                           className={classnames('form-control',{
                                           'errors' : errors.tachyhr_upper
                                           })} 
                                           name="tachyhr_upper" 
                                           required="" 
                                           id="tachyhr_upper" 
                                           onChange={this.onChange}
                                           value={this.state.tachyhr_upper} 
                                           />
                                        </div>
                                     </div>
                                     <div className="col-md-4 multi-horizontal">
                                        <div className="form-group">
                                           <label className="form-control-label mbr-fonts-style display-7">Brady Lower Limit </label>
                                           <input 
                                           type="text" 
                                           className={classnames('form-control',{
                                           'errors' : errors.bradyhr_lower
                                           })} 
                                           name="bradyhr_lower" 
                                           required="" 
                                           id="bradyhr_lower" 
                                           onChange={this.onChange}
                                           value={this.state.bradyhr_lower} 
                                           />
                                        </div>
                                     </div>
                                     <div className="col-md-4 multi-horizontal">
                                        <div className="form-group">
                                           <label className="form-control-label mbr-fonts-style display-7">Brady Upper Limit  </label>
                                           <input 
                                           type="text" 
                                           className={classnames('form-control',{
                                           'errors' : errors.bradyhr_upper
                                           })} 
                                           name="bradyhr_upper" 
                                           required="" 
                                           id="bradyhr_upper" 
                                           onChange={this.onChange}
                                           value={this.state.bradyhr_upper} 
                                           />
                                        </div>
                                     </div>
                                  </div>
                                  <div className="row row-sm-offset">
                                     <div className="col-md-4 multi-horizontal">
                                        <div className="form-group">
                                           <label className="form-control-label mbr-fonts-style display-7">ST Depression (mm)</label>
                                           <input 
                                           type="text" 
                                           className={classnames('form-control',{
                                           'errors' : errors.stdepression
                                           })} 
                                           name="stdepression" 
                                           required="" 
                                           id="stdepression" 
                                           onChange={this.onChange}
                                           value={this.state.stdepression} 
                                           />
                                        </div>
                                     </div>
                                     <div className="col-md-4 multi-horizontal">
                                        <div className="form-group">
                                           <label className="form-control-label mbr-fonts-style display-7">Pause Length (s)  </label>
                                           <input 
                                           type="text" 
                                           className={classnames('form-control',{
                                           'errors' : errors.pauselength
                                           })} 
                                           name="pauselength" 
                                           required="" 
                                           id="pauselength" 
                                           onChange={this.onChange}
                                           value={this.state.pauselength} 
                                           />
                                        </div>
                                     </div>
                                     <div className="col-md-4 multi-horizontal">
                                        <div className="form-group">
                                           <label className="form-control-label mbr-fonts-style display-7">Graphs To Be Generated(no:)  </label>
                                           <input 
                                           type="text" 
                                           className={classnames('form-control',{
                                           'errors' : errors.graph_gen
                                           })} 
                                           name="graph_gen" 
                                           required="" 
                                           id="graph_gen" 
                                           onChange={this.onChange}
                                           value={this.state.graph_gen} 
                                           />
                                        </div>
                                     </div>
                                  </div>
                                        
                                </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-success">Submit</button>
                                <button id="closePopup" type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                            </div>
                        </form>
                    </div>
                  </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="panel panel-default card-view">
                            <div className="panel-heading">
                                <div className="pull-left">
                                    <h6 className="panel-title txt-dark">Patient List</h6>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                            <div className="panel-wrapper collapse in">
                                <div className="panel-body">
                                    <MDBDataTable
                                    id="mbdTable"
                                      noBottomColumns
                                      data={this.state.data}
                                    />
                                </div>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
  }
}


export default DoctorHome;