import React, { Component } from "react";
import axios from "axios";
import apiUrl from "../../config/config";
import classnames from "classnames";
import $ from "jquery";
import socketIOClient from "socket.io-client";
import { MDBDataTable } from "mdbreact";
import ProgressBtn from "../../components/common/ProgressButton";
import TopNav from "../../components/common/TopNav";
import SideBar from "../../components/common/SideBar";
import AddModalComplete from "../verifier/AddModalComplete";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Select from "react-select";
import AddModalReport from "./AddModalReport";
import { format, parseISO } from "date-fns";
import "./patient-list.css";

import LinearProgress from "@material-ui/core/LinearProgress";
// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from "worker-loader!./worker.js";
import { duration } from "@material-ui/core";
import { time } from "highcharts";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";

const socket = socketIOClient("https://web.mybiocalculus.com:5000");

const customStyles = {
  root: {
    display: "flex",
    alignItems: "center",
  },
  wrapper: {
    margin: "10px",
    position: "relative",
  },
  linearProgress: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100%",
    opacity: 0.4,
    borderRadius: 4,
  },
};
class PatientList extends Component {
  constructor() {
    super();
    this.state = {
      menus: [
        { title: "Verifier Home", url: "/verifier-home" },
        { title: "Patient list", url: "/patient-list" },
      ],
      isModalCompleteOpen: false,
      profileLink: "verifier-profile",
      patients: [],
      nor_minhr: "",
      nor_maxhr: "",
      qtcformula: "",
      qtcvalue_min: "",
      qtcvaluemax: "",
      qrswidth_min: "",
      qrswidth_max: "",
      qrsamp_min: "",
      qrsamp_max: "",
      stelevation: "",
      pwidth_min: "",
      pwidth_max: "",
      pamp_min: "",
      pamp_max: "",
      stdepression: "",
      twidth_min: "",
      twidth_max: "",
      macid: "",
      tamp_min: "",
      tamp_max: "",
      printerval_min: "",
      printerval_max: "",
      tachyhr_lower: "",
      tachyhr_upper: "",
      bradyhr_lower: "",
      bradyhr_upper: "",
      pauselength: "",
      graph_gen: "",
      errors: {},
      userid: "",
      smoking: "",
      diabetes: "",
      height: "",
      weight: "",
      hospital: "",
      phone: "",
      patient_name: "",
      email_id: "",
      dob: null,
      af: "",
      hyper_tension: "",
      hyper_lipidemia: "",
      indication: "",
      thyroid_function: "",
      cardiac_surgery: "",
      doctors: [],
      verifiers: [],
      selectedVerifierIds: [],
      selectedVerifiers: [],
      doctor_id: "",
      doctor_name: "Choose",
      doctors: [],
      selectedDoctor: null,
      searchTerm: "",
      data: {},
      prog_data: 0,
      loading: false,
      socketNew: socket,
      patientDetails: null,
      ipop: "",
      priority: "",
      verifiertype: "",
      allverifiers: "",
      reportstatus: "",
      isModalReportOpen: false,
      generatereportuserid: "",
      treemenu: [],
      duration: "",
      customer_details: "",
      showButton: true,
      refreshKey: 0,
      filetype: "",
      sortedPatients: [],
      selectedVerifiersEfficency: [],
      hourstocompleted: "",
      gender: "",

      // confrimTime:[],
    };
    this.intervalId = null;

    this.selectItem = this.selectItem.bind(this);
    this.getInfo = this.getInfo.bind(this);
    this.handleModalComplete = this.handleModalComplete.bind(this);

    this.submitComment = this.submitComment.bind(this);
    this.preventDefault = this.preventDefault.bind(this);
    this.getConfirmTime = this.getConfirmTime.bind(this);
    this.HandleVerifiedSpeed = this.HandleVerifiedSpeed.bind(this);
    this.getverifiedprogress = this.getverifiedprogress.bind(this);
    this.onGenderChange = this.onGenderChange.bind(this);
  }
  componentDidMount() {
    // Fetch data initially
    this.fetchData();

    // Set an interval to check the condition and fetch data
    this.intervalId = setInterval(() => {
      if (!this.state.isModalCompleteOpen) {
        this.fetchData();
      } else {
        console.log("Modal is open, skipping data refresh");
      }
    }, 30000); // 30 seconds
  }

  componentWillUnmount() {
    // Clear the interval when the component unmounts
    clearInterval(this.intervalId);
  }

  fetchData = () => {
    const verifiertype = localStorage.getItem("verifiertype");
    this.setState({ verifiertype });

    const worker = new Worker();

    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    var newstat = this;

    worker.postMessage({
      name: "alldoctors",
      url: apiUrl + "api/users/alldoctors",
    });

    worker.addEventListener("message", function (event) {
      newstat.setState({ doctors: event.data });
    });

    axios
      .all([
        axios.get(apiUrl + "api/users/getVerifierPatientsPercentage"),
        axios.get(apiUrl + "api/users/allverifiers"),
      ])
      .then(
        axios.spread((patientsRes, verifiersRes) => {
          this.setState(
            {
              patients: patientsRes.data.sortedPatients,
              allverifiers: verifiersRes.data,
            },
            () => {
              // Updated sorting logic

              const sortedPatients = this.state.patients.sort((a, b) => {
                // Determine priority for each patient (default to 4 if not defined)
                const priorityA =
                  a.deviceInfo && a.deviceInfo.priority !== undefined
                    ? a.deviceInfo.priority
                    : 4;
                const priorityB =
                  b.deviceInfo && b.deviceInfo.priority !== undefined
                    ? b.deviceInfo.priority
                    : 4;

                // First, compare priorities (ascending order)
                if (priorityA !== priorityB) {
                  return priorityA - priorityB;
                }

                // If priorities are the same, compare updateddate (descending order)
                const updatedDateA = new Date(a.updateddate);
                const updatedDateB = new Date(b.updateddate);

                // Return descending order comparison of updateddate
                return updatedDateB - updatedDateA;
              });

              // console.log('percentage',patientsWithPercentages);  // Log the updated patients array with percentage
              console.log("sorted patients!!!!!!!!!!!!!!!", sortedPatients);

              const data = {
                columns: [
                  { label: "SL NO", field: "slno", sort: "asc", width: 0.5 },
                  {
                    label: "PATIENT ID",
                    field: "patientId",
                    sort: "asc",
                    width: 5,
                  },
                  {
                    label: "ADDED DATE",
                    field: "added_date",
                    sort: "asc",
                    width: 5,
                  },
                  { label: "NAME", field: "name", sort: "asc", width: 5 },
                  {
                    label: "DISTRIBUTOR",
                    field: "distributor",
                    sort: "disabled",
                    width: 5,
                  }, // Existing column
                  {
                    label: "DOCTOR NAME",
                    field: "doctor_name",
                    sort: "asc",
                    width: 5,
                  },
                  {
                    label: "Priority",
                    field: "priority",
                    sort: "disabled",
                    width: 5,
                  }, // Existing column

                  {
                    label: "ACTION",
                    field: "action",
                    sort: "disabled",
                    width: 5,
                  },
                  ...(this.state.verifiertype === "8"
                    ? [
                        {
                          label: "REPORT",
                          field: "report",
                          sort: "disabled",
                          width: 5,
                        },
                      ]
                    : []),
                  {
                    label: "MONITORING",
                    field: "monitoring_duration",
                    sort: "disabled",
                    width: 5,
                  }, // Existing column
                ],
                rows: [],
              };
              sortedPatients.forEach((patient, i) => {
                const Priority =
                  (patient.deviceInfo && patient.deviceInfo.Priority) || 4; // Set Priority value

                // Variable to store the final time value
                let finalTime;

                // Ensure the date fields are not undefined or null before splitting
                if (patient.ConfirmupdatedDate) {
                  // Split the 'ConfirmupdatedDate' by 'T' to get the date and time parts
                  let confirmButtonTime = patient.ConfirmupdatedDate.split("T");

                  // Get the date part before 'T'
                  let confirmDate = confirmButtonTime[0]; // '2024-11-29'

                  // Get the time part after 'T'
                  let confirmTime = confirmButtonTime[1]
                    ? confirmButtonTime[1].split("Z")[0] // Remove 'Z' if it exists
                    : ""; // If no time part, default to an empty string

                  // Handle removing milliseconds (if any) from confirmTime
                  if (confirmTime) {
                    let time = confirmTime.split(".")[0]; // Get time without milliseconds
                    const confirmbtnTime = confirmDate + " " + time; // Add a space between date and time
                    // console.log("Confirm button time:", confirmbtnTime);

                    // Assign confirmbtnTime to finalTime if available
                    finalTime = confirmbtnTime;
                  } else {
                    // console.log("Confirm Time is empty or invalid");
                  }
                } else {
                  // console.log("ConfirmupdatedDate is undefined or null");
                }

                // Check and split the 'updatedDate' if it's available
                if (patient.updatedDate) {
                  // Split the 'updatedDate' by space to separate date and time
                  let updatedDateTime = patient.updatedDate.split(" ");

                  // Get the date part before the space
                  let updatedDate = updatedDateTime[0]; // '2024-11-29'

                  // Get the time part after the space
                  let updatedTime = updatedDateTime[1]; // '10:28:34'

                  // console.log("Updated Date:", updatedDate, updatedTime);

                  // Combine updatedDate and updatedTime with a space between them
                  const uploadedTime = updatedDate + " " + updatedTime;

                  // Assign uploadedTime to finalTime if confirmbtnTime was not set
                  if (!finalTime) {
                    finalTime = uploadedTime;
                  }
                } else {
                  // console.log("UpdatedDate is undefined or null");
                }

                // Log the final time
                // console.log("Final Time:", finalTime);

                // Get the current time
                const now = new Date();

                // Convert finalTime to Date object
                const finalDate = new Date(finalTime);

                // Calculate the time difference in milliseconds
                const timeDifference = now - finalDate; // in milliseconds

                // Convert time difference to hours
                const hoursDifference = timeDifference / (1000 * 60 * 60); // milliseconds to hours

                // Set the time limit based on priority
                let timeLimit;
                switch (Priority) {
                  case 1:
                    timeLimit = 1; // 1 hours
                    break;
                  case 2:
                    timeLimit = 2; // 3 hours
                    break;
                  case 3:
                    timeLimit = 3;
                  case 4:
                    timeLimit = 4; // 4 hours
                    break;
                  case 5:
                    timeLimit = 5; // 8 hours
                    break;
                  default:
                    timeLimit = 4; // 10 hours (for priority 5, as an example)
                }

                // Calculate the percentage of time used
                let timePercentage =
                  (hoursDifference /
                    (timeLimit * patient.exp_device_use_days)) *
                  100;
                // *
                // patient.exp_device_use_days
                if (timePercentage > 100) {
                  timePercentage = 100; // Limit to 100% if the time exceeds the limit
                }
                // Calculate the completion rate (percentage per hour)
                // Calculate the completion rate (percentage per hour)
                const completionRate = timePercentage / hoursDifference; // percentage per hour

                // Predict the total time to complete 100% (in hours)
                const totalCompletionTime = 100 / completionRate; // in hours

                // Calculate the remaining time to complete (in hours)
                const remainingTimeInHours =
                  totalCompletionTime - hoursDifference;

                // Convert the remaining time to minutes
                const remainingTimeInMinutes = remainingTimeInHours * 60;

                // Convert minutes into hours and minutes
                const hours = Math.floor(remainingTimeInMinutes / 60); // Extract whole hours
                const minutes = Math.round(remainingTimeInMinutes % 60); // Get the remaining minutes

                // Log the predicted remaining time
                // console.log(
                //   `Predicted Remaining Time: ${hours} hours and ${minutes} minutes`
                // );

                // console.log("name", patient.name);
                // console.log("percetage", timePercentage);
                // console.log("timediff", timeDifference);
                // console.log("time limit", timeLimit);

                // Log the result
                // console.log(`Time used: ${hoursDifference.toFixed(2)} hours`);
                // console.log(`Time limit for Priority ${Priority}: ${timeLimit} hours`);
                // console.log(`Percentage of time used: ${timePercentage.toFixed(2)}%`);
                const percentage = timePercentage.toFixed(2);
                const devicemac =
                  patient && patient.deviceInfo && patient.deviceInfo.mac_id;
                // console.log("patient", devicemac);
                const Distributor_name =
                  patient &&
                  patient.deviceInfo &&
                  patient.deviceInfo.customer_details;
                const devicepriority =
                  patient && patient.deviceInfo && patient.deviceInfo.priority;

                const totalEcgValues = patient.ecgValues.length;
                const verifiedEcgValues = patient.ecgValues.filter(
                  (ecgValue) => ecgValue.verifyStatus === 1
                );
                const verifiedPercentage = `${verifiedEcgValues.length} /${totalEcgValues} `;

                const createdData = patient.created_at.split("T");

                let url = " ";
                let anl_btn_txt = "teststart";
                let anl_btn_class = "btn-danger";
                let gen_btn_txt = "Not Generated";
                let gen_btn_class = "btn-danger";
                let report_link = "#";
                let report_link_target = "";

                if (patient.LastDatasetId !== undefined) {
                  url =
                    patient.backup_status === 0 ||
                    patient.backup_status === undefined
                      ? `/verifier-unanalysed/${patient._id}/${patient.LastDatasetId}`
                      : " ";
                }

                if (patient.report_status >= 1) {
                  anl_btn_txt = "Analyzing...";
                  anl_btn_class = "btn-success";
                }

                if (patient.report_status >= 2) {
                  anl_btn_txt = "test";
                  anl_btn_class = "btn-success";
                  url =
                    patient.backup_status === 0 ||
                    patient.backup_status === undefined
                      ? `/verifier-chart/${patient._id}/${patient.LastDatasetId}`
                      : " ";
                }

                if (patient.report_status >= 4) {
                  gen_btn_txt = "In Preparation";
                  gen_btn_class = "btn-yellow";
                }
                if (patient.report_status >= 5) {
                  gen_btn_txt = "View Report";
                  gen_btn_class = "btn-success";
                  report_link_target = "_blank";
                  report_link =
                    "https://web.mybiocalculus.com/assets/dist/user_records/" +
                    patient._id +
                    "/Report" +
                    patient._id +
                    ".pdf";
                }

                anl_btn_txt =
                  patient.backup_status === 0 ||
                  patient.backup_status === undefined
                    ? "TEST3"
                    : patient.backup_status === 1
                    ? "Retrieve"
                    : "Retrieving";

                const updateddate = patient.ConfirmupdatedDate
                  ? patient.ConfirmupdatedDate.replace("T", " ").split(
                      ".000Z"
                    )[0]
                  : patient.updatedDate;

                data.rows.push({
                  slno: i + 1,
                  patientId: patient.device_id,
                  added_date: createdData[0],

                  name: capitalizeFirstLetter(patient.name), // Default value
                  distributor: Distributor_name, // Default value // Ensure `distributor` is populated
                  doctor_name: patient.doctorName, // Default value
                  priority: patient.priority || "N/A",

                  // Ensure `doctor_name` is populated
                  action: (
                    <React.Fragment>
                      <div
                        style={{
                          margin: "auto",
                          width: "132%",
                          marginLeft: "-14%",
                        }}
                      >
                        <ProgressBtn
                          id={patient._id}
                          bc_stat={patient.backup_status}
                          onSelectModalComplete={this.handleModalComplete}
                          r_stat={patient.report_status}
                          socket={this.state.socketNew}
                        />
                        {this.state.verifiertype === "8" && (
                          <button
                            type="button"
                            id={patient._id}
                            dataid2={devicemac}
                            dataid3={devicepriority}
                            style={{
                              float: "left",
                              marginRight: "10px",
                              width: "67px",
                            }}
                            onClick={this.getInfo}
                            className="btn btn-warning"
                            data-toggle="modal"
                            data-target="#editInfoModal"
                          >
                            Edit
                          </button>
                        )}
                        <button
                          key={patient.id}
                          id={patient._id}
                          hourcomplete={`${hours} hours and ${minutes} minutes`}
                          type="button"
                          style={{
                            float: "left",
                            marginRight: "10px",
                            width: "90px",
                          }}
                          className="btn btn-primary"
                          data-toggle="modal"
                          onClick={this.getverifiedprogress}
                          data-target="#progressinfomodal"
                        >
                          {verifiedPercentage}
                        </button>
                        {this.state.verifiertype === "8" && (
                          <select
                            style={{
                              color: "black",
                              width: "110px",
                              height: "36px",
                              float: "left",
                              backgroundColor: "gray",
                              border: "none",
                            }}
                          >
                            {this.state.allverifiers.filter((verifier) =>
                              verifier.patients.includes(patient._id)
                            ).length === 0 && (
                              <option
                                style={{ color: "gray" }}
                                disabled
                                selected
                              >
                                Not Assigned
                              </option>
                            )}
                            {this.state.allverifiers
                              .filter((verifier) =>
                                verifier.patients.includes(patient._id)
                              )
                              .map((verifier) => (
                                <option
                                  style={{ color: "black" }}
                                  key={verifier._id}
                                  value={verifier._id}
                                >
                                  {verifier.name}
                                </option>
                              ))}
                          </select>
                        )}

                        <button
                          type="button"
                          id={patient._id}
                          style={{
                            float: "left",
                            marginLeft: "10px",
                            width: "130px",
                            marginRight: "10px",
                          }}
                          onClick={this.getInfo}
                          className="btn btn-warning"
                          data-toggle="modal"
                          data-target="#commentinfomodal"
                        >
                          comments
                        </button>

                        {this.state.verifiertype === "8" &&
                        !patient.ConfirmupdatedDate ? (
                          <button
                            type="button"
                            id={patient._id}
                            style={{
                              float: "left",
                              marginLeft: "0px",
                              width: "80px",
                              marginRight: "10px",
                              backgroundColor: "transparent",
                              border: "none",
                              fontSize: "30px",
                              padding: "10px 20px",
                              borderRadius: "5px",
                              cursor: "pointer",
                            }}
                            onClick={this.getConfirmTime}
                            className="btn"
                          >
                            ✅
                          </button>
                        ) : null}
                      </div>
                    </React.Fragment>
                  ),

                  report: (
                    <div
                      style={
                        this.state.verifiertype !== "8"
                          ? { display: "none", marginLeft: "11%", width: "93%" }
                          : { marginLeft: "11%", width: "93%" }
                      }
                    >
                      {/* Progress bar to show the percentage of completion */}
                      <div style={{ margin: "10px 0" }}>
                        <label htmlFor="progressBar">
                          TAT: {hours} Hrs:{minutes} min
                        </label>
                        <div
                          style={{
                            height: "20px",
                            width: "106%",
                            backgroundColor: "#e0e0df",
                            borderRadius: "5px",
                            overflow: "hidden",
                            marginTop: "5px",
                            position: "relative",
                          }}
                        >
                          {/* Progress bar filled section */}
                          <div
                            style={{
                              height: "100%",
                              width: `${timePercentage}%`, // Adjust based on percentage value
                              backgroundColor:
                                timePercentage >= 80
                                  ? "#8f0303"
                                  : timePercentage >= 50
                                  ? "orange"
                                  : "#2ecd99", // Red for 80-100%, Orange for 50-79%, Green otherwise
                              transition: "width 0.5s ease",
                              position: "absolute",
                              top: 0,
                              left: 0,
                            }}
                          ></div>

                          {/* Centered percentage text */}
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              height: "100%",
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontWeight: "bold",
                              color: "#120d0d", // Sets the text color to black
                            }}
                          >
                            {timePercentage.toFixed(2)}%
                          </div>
                        </div>
                      </div>

                      <React.Fragment>
                        <a
                          href={report_link}
                          target={report_link_target}
                          id={patient._id}
                          style={
                            gen_btn_class === "btn-yellow"
                              ? { backgroundColor: "#2ecd99" }
                              : {}
                          }
                          onClick={() =>
                            this.generateReport(
                              patient.report_status,
                              patient._id
                            )
                          }
                        >
                          <button
                            type="button"
                            className={`btn ${gen_btn_class}`}
                            style={
                              gen_btn_class === "btn-yellow"
                                ? { backgroundColor: "#2ecd99" }
                                : {}
                            }
                          >
                            {gen_btn_txt}
                          </button>
                        </a>
                        <input
                          type="hidden"
                          className="patientLink"
                          value={url}
                        />
                      </React.Fragment>
                    </div>
                  ),

                  monitoring_duration: (
                    <div style={{ width: "115px" }}>
                      {patient.exp_device_use_days}DAYS ({updateddate})
                    </div>
                  ),
                  TimePercentage: (
                    <div
                      style={{
                        color: "transparent",
                        display: "flex",
                        position: "absolute",
                      }}
                    >
                      {percentage}
                    </div>
                  ),
                  hourstocompleted: (
                    <div
                      style={{
                        color: "transparent",
                        display: "flex",
                        position: "absolute",
                      }}
                    >
                      {`${hours} hours and ${minutes} minutes`}
                    </div>
                  ),
                });
              });

              this.setState({ data }, () => {
                this.applyRowStyles();

                // Bind click events for table rows
                const table = document.getElementById("mbdTable");
                table.addEventListener("click", (event) => {
                  const target = event.target;
                  if (
                    target.closest(
                      "td:nth-child(2), td:nth-child(3), td:nth-child(4)"
                    )
                  ) {
                    const url = target
                      .closest("tr")
                      .querySelector(".patientLink").value;
                    if (url !== " ") {
                      window.location.href = url;
                      // console.log("url:", url);
                    }
                  }
                });
              });
            }
          );
        })
      )
      .catch((err) => {
        console.error("Error fetching data:", err);
      });
  };
  applyRowStyles() {
    const { data } = this.state;

    if (data && data.rows) {
      // Delay the application of styles until the table renders
      setTimeout(() => {
        $("#mbdTable tbody tr").each((index, element) => {
          const rowData = data.rows[index];
          const percentage = parseFloat(rowData.TimePercentage.props.children); // Get the percentage as a float

          // // Apply the priority-based color styles
          // if (rowData && rowData.priority === "1") {
          //   $(element).css("background-color", "#008080"); // Teal for priority 1
          // } else if (rowData && rowData.priority === "2") {
          //   $(element).css("background-color", "#2ecd99"); // Greenish for priority 2
          // } else if (rowData && rowData.priority === "3") {
          //   $(element).css("background-color", "#5F9EA0"); // Cadet blue for priority 3
          // } else {
          //   $(element).css("background-color", "#9ba59c"); // Default grey color
          // }

          // Apply percentage-based color styles
          if (percentage >= 50 && percentage <= 70) {
            $(element).css("background-color", "#008080"); // Light green for percentage 50-60
          } else if (percentage >= 71 && percentage <= 90) {
            $(element).css("background-color", "#2ecd99"); // Light yellow for percentage 61-70
          } else if (percentage >= 91 && percentage <= 100) {
            $(element).css("background-color", "#e0544a"); // Dark yellow for percentage 71-80
          } else {
            $(element).css("background-color", "#8f8b8b");
          }
        });
      }, 100);
    }
  }

  submitComment = async (e) => {
    const userid = e.currentTarget.id;
    const verifiername = localStorage.getItem("verifiername");
    //  console.log(verifiername)
    const comments = `${this.state.comments} - BY ${verifiername}`; // Assuming it's a string
    // console.log(comments)

    try {
      const response = await axios.post(
        `${apiUrl}api/patients/addComment/${userid}`,
        { comments }
      );
      // console.log('Response:', response.data);
      $(".closePopup").click();

      this.setState({ comments: "" }); // Clear the input field
      this.fetchData();
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };

  // Method to apply styles to rows based on priority

  handleDoctorChange = (selectedOption) => {
    this.setState({ selectedDoctor: selectedOption });
    // console.log("Selected doctor:", selectedOption);
    // Perform any action needed with the selected doctor
  };

  async generateReport(reportstatus, userid) {
    //  const userid=e.currentTarget.id

    // console.log("generate event id:", userid);
    this.setState({ generatereportuserid: userid });
    if (reportstatus == "2") {
      const res = await axios.get(
        apiUrl + "api/common/patinentmenutreeNew?userid=" + userid
      );

      this.setState({ treemenu: res.data });

      this.setState({ isModalReportOpen: true });
    }
    if (reportstatus == "4") {
      const res = await axios.get(
        apiUrl + "api/common/patinentmenutreeNew?userid=" + userid
      );

      this.setState({ treemenu: res.data });
      this.setState({ isModalReportOpen: true });
    } else {
      this.setState({ isModalReportOpen: false });
    }
  }

  handleVerifierChange = (selectedOptions) => {
    this.setState({
      selectedVerifiers: selectedOptions || [], // Update selected verifiers or set empty array if none
    });
    // console.log("selected options", selectedOptions);
    // console.log(
    //   "selected verifier state",
    //   this.state.selectedVerifiers,
    //   this.state.selectedVerifierIds
    // );
  };

  handleModalComplete(id) {
    // console.log("id:", id);
    this.state.userid = id;
    this.setState({ isModalCompleteOpen: true });
  }

  onSubmit = (e) => {
    e.preventDefault();

    const settings = {
      userid: this.state.userid,
      nor_minhr: this.state.nor_minhr,
      nor_maxhr: this.state.nor_maxhr,
      qtcformula: this.state.qtcformula,
      qtcvalue_min: this.state.qtcvalue_min,
      qtcvaluemax: this.state.qtcvaluemax,
      qrswidth_min: this.state.qrswidth_min,
      qrswidth_max: this.state.qrswidth_max,
      qrsamp_min: this.state.qrsamp_min,
      qrsamp_max: this.state.qrsamp_max,
      stelevation: this.state.stelevation,
      pwidth_min: this.state.pwidth_min,
      pwidth_max: this.state.pwidth_max,
      pamp_min: this.state.pamp_min,
      pamp_max: this.state.pamp_max,
      stdepression: this.state.stdepression,
      twidth_min: this.state.twidth_min,
      twidth_max: this.state.twidth_max,
      tamp_min: this.state.tamp_min,
      tamp_max: this.state.tamp_max,
      printerval_min: this.state.printerval_min,
      printerval_max: this.state.printerval_max,
      tachyhr_lower: this.state.tachyhr_lower,
      tachyhr_upper: this.state.tachyhr_upper,
      bradyhr_lower: this.state.bradyhr_lower,
      bradyhr_upper: this.state.bradyhr_upper,
      pauselength: this.state.pauselength,
      graph_gen: this.state.graph_gen,
    };

    // console.log(settings);
    axios
      .post(apiUrl + "api/patients/updateSettings", settings)
      .then((res) => {
        $("#closePopup").click();
        // console.log("onclick");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onSubmitInfo = (e) => {
    e.preventDefault();

    //   this.state.selectedVerifiers.forEach(verifier => {
    //     console.log("Verifier ID:", verifier.value);
    // });

    const info = {
      name: this.state.patient_name,
      patientid: this.state.userid,
      userid: this.state.userid,
      dob: this.state.dob,
      weight: this.state.weight,
      height: this.state.height,
      hospital: this.state.hospital,
      phone: this.state.phone,
      email: this.state.email_id,
      doctorid: this.state.selectedDoctor.value,
      ipop: this.state.ipop,
      fileType: this.state.filetype || "easy",
      verifierid: this.state.selectedVerifiers.map(
        (verifier) => verifier.value
      ),
      priority: this.state.priority,

      reportstatus: this.state.reportstatus,
      // smoking: this.state.smoking,
      // diabetes: this.state.diabetes,
      af: this.state.af,
      // hyper_tension: this.state.hyper_tension,
      // hyper_lipidemia: this.state.hyper_lipidemia,
      indication: this.state.indication,
      cardiac_surgery: this.state.cardiac_surgery,
      thyroid: this.state.thyroid_function,
      exp_device_use_days: this.state.duration,
    };
    // console.log('info:',info);
    // console.log("info date!!!!!!!!!",this.state);
    const deviceinfoupdate = {
      mac_id: this.state.macid,
      priority: this.state.priority,
    };

    axios
      .post(apiUrl + "api/patients/updateinfo_new", info)
      .then((res) => {
        // Handle the response of the first request
        // console.log("Patient info updated:", res.data);
        if (this.state.macid != null) {
          // If macid is either undefined or null
          return axios.put(
            `${apiUrl}api/patients/updateDevicePriority`,
            deviceinfoupdate
          );
        }
      })
      .then((res) => {
        // Handle the response of the second request
        // console.log("Device priority updated:", res.data);
        $(".closePopup").click();
        this.fetchData(); // Fetch updated data
      })
      .catch((err) => {
        // Handle any errors from either request
        console.error("Error occurred:", err);
      });
  };

  onChange = (e) => {
    var err = this.state.errors;
    delete err[e.target.name];
    // console.log("onChange");
    // console.log(e.target.name);
    // console.log(e.target.value);
    // console.log(e.target)
    // this.setState({reportstatus:e.target.value})
    // console.log(e.target.value)

    this.setState({ [e.target.name]: e.target.value });
  };
  handleTypeChange = (e) => {
    // console.log("e.target.value", e.target.value);
    this.setState({ filetype: e.target.value });
  };
  handleDateChange = (inputDate) => {
    // Check if the input date is a valid ISO string
    if (moment(inputDate, moment.ISO_8601, true).isValid()) {
      // Convert the input string into a Date object
      const date = new Date(inputDate);

      // Format the date as desired (ISO format with time zone offset)
      const formattedDate = format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");

      // console.log("Formatted Date:", formattedDate);
      // Outputs: 2024-11-06T00:00:00.000+05:30
      this.setState({ dob: formattedDate });
    } else {
      console.error("Invalid date format:", inputDate);
    }
  };
  onIpOpChange = (e) => {
    console.log("ipop", e.target.value);
    this.setState({ ipop: e.target.value });
  };
  onDurationChange = (e) => {
    var err = this.state.errors;
    delete err[e.target.name];
    this.setState({ duration: e.target.value });
    console.log(this.state.duration);
  };
  onPriorityChange = (e) => {
    var err = this.state.errors;
    delete err[e.target.name];

    // console.log(e.target.value)
    this.setState({ priority: e.target.value });
  };
  onReportStatuschange = (e) => {
    // console.log("report stats change",e.target.value)
    this.setState({ reportstatus: e.target.value });
  };
  onGenderChange = (e) => {
    this.setState({ gender: e.target.value });
  };

  preventDefault(e) {
    e.preventDefault();
  }
  HandleVerifiedSpeed(e) {
    console.log(e.target.value);
  }
  getInfo(e) {
    this.setState({
      smoking: "",
      diabetes: "",
      af: "",
      hyper_tension: "",
      hyper_lipidemia: "",
      indication: "",
      cardiac_surgery: "",
      thyroid_function: "",
    });
    this.setState({ isModalCompleteOpen: false });

    var userid = e.currentTarget.id;
    const dataid2 = e.target.getAttribute("dataid2");
    const dataid3 = e.target.getAttribute("dataid3");

    // console.log("current target", e.currentTarget);
    this.setState({ userid: userid });

    axios
      .get(`${apiUrl}api/patients/getPatinetAdditionalInfo?userid=${userid}`)
      .then((res) => {
        const data = res.data[0];
        if (
          data.gender === "MALE" ||
          data.gender === "male" ||
          data.gender === "Male" ||
          data.gender === "M" ||
          data.gender === "m"
        ) {
          this.setState({ gender: "M" });
        }

        if (
          data.gender === "FEMALE" ||
          data.gender === "female" ||
          data.gender === "Female" ||
          data.gender === "F" ||
          data.gender === "f"
        ) {
          this.setState({ gender: "F" });
        }
        if (data.gender === "Select Gender") {
          this.setState({ gender: "M" });
        }

        if (Array.isArray(this.state.patients)) {
          this.state.patients.forEach((patient) => {
            if (patient._id === userid) {
              this.setState({ reportstatus: patient.report_status });
              // console.log("report status", patient.report_status);
            }
          });
        }
        data.customer_details.forEach((customer) => {
          if (customer.customer_details) {
            // console.log('Nested Customer Details:', customer);
            this.setState({ customer_details: customer.customer_details });
          }
        });
        // console.log(this.state.patients);

        // Update state with patient info
        this.setState({
          af: data.af ? "yes" : "no",
          duration: data.exp_device_use_days,
          cardiac_surgery: data.cardiac_surgery ? "yes" : "no",
          thyroid_function: data.thyroid,
          diabetes: data.diabetes,
          indication: data.indication,
          height: data.height,
          weight: data.weight,
          hospital: data.hospital,
          phone: data.phone,
          patient_name: data.name,
          email_id: data.email,
          selectedDoctor: { value: data.doctor_id, label: data.doctor_name },
          macid: dataid2,
          priority: dataid3,
          dob: data.dob,
          duration: data.exp_device_use_days,
          filetype: data.fileType,
        });

        // Handle DOB formatting
        const dob = new Date(Date.parse(data.dob));
        const formattedDob = `${
          dob.getMonth() + 1
        }/${dob.getDate()}/${dob.getFullYear()}`;
        this.setState({ dob: formattedDob });

        // Second API call for verifiers
        return axios.get(`${apiUrl}api/users/allverifiers`);
      })
      .then((res) => {
        const verifiers = res.data;
        const formattedVerifiers = verifiers.map((verifier) => ({
          value: verifier._id,
          label: verifier.name,
          patients: verifier.patients, // Assuming patients is an array of user IDs
        }));

        const filteredVerifiers = formattedVerifiers.filter((verifier) =>
          verifier.patients.includes(userid)
        );

        // console.log('Filtered verifiers with matching user:', this.state.priority);

        this.setState({ selectedVerifiers: filteredVerifiers });

        this.setState({ verifiers: formattedVerifiers });
        // console.log('formatted verifiers:',verifiers)
        // Third API call for patient search (ipop)
        this.state.patients.forEach((patient) => {
          if (patient._id === userid) {
            this.setState({ comments: patient.comments });
            // this.setState({ priority: patient.priority });
            this.setState({ ipop: patient.ip_op });
          }
        });

        // console.log("ipop", this.state.patients);

        // Fourth API call for verified percentage
        return axios.get(`${apiUrl}api/users/getVerifiedPercentage/${userid}`);
      })
      .then((res) => {
        const ecgValues = res.data.ecgValues; // Assuming `ecgValues` is an array
        const totalLength = ecgValues.length;
        let verifyCount = 0; // Initialize verify count

        ecgValues.forEach((value, index) => {
          if (value.verifyStatus === 1) {
            // console.log(`Link for ECG value at index ${index}:`, value.link); // Assuming there's a 'link' property
            verifyCount++; // Increment the verify count
          }
        });

        // console.log('Total verified ECG values:', verifyCount);
        // console.log('Total count:', totalLength);

        if (totalLength > 0) {
          // console.log('ECG data is available.');

          // Calculate and log the percentage of verified ECG values
          const percentageVerified = (verifyCount / totalLength) * 100;
          // console.log('Percentage of verified ECG values:', percentageVerified.toFixed(2) + '%');
          this.setState({ percentageVerified: percentageVerified.toFixed(2) });
          // console.log('verified percentage:',this.state.percentageVerified)
        } else {
          // console.log('No ECG data found.');
        }
      })
      .catch((error) => {
        console.error("Error fetching ECG data:", error);
      });
  }
  getverifiedprogress(e) {
    this.setState({ isModalCompleteOpen: false });
    this.setState({
      smoking: "",
      diabetes: "",
      af: "",
      hyper_tension: "",
      hyper_lipidemia: "",
      indication: "",
      cardiac_surgery: "",
      thyroid_function: "",
      hourstocompleted: "",
    });

    var userid = e.currentTarget.id;
    const dataid2 = e.target.getAttribute("dataid2");
    const dataid3 = e.target.getAttribute("dataid3");
    const hourstocompleted = e.target.getAttribute("hourcomplete");
    this.setState({ hourstocompleted: hourstocompleted });

    // console.log("current target", hourstocompleted);
    this.setState({ userid: userid });

    axios
      .get(`${apiUrl}api/patients/getPatinetAdditionalInfo?userid=${userid}`)
      .then((res) => {
        const data = res.data[0];

        if (Array.isArray(this.state.patients)) {
          this.state.patients.forEach((patient) => {
            if (patient._id === userid) {
              this.setState({ reportstatus: patient.report_status });
              // console.log("report status", patient.report_status);
            }
          });
        }
        data.customer_details.forEach((customer) => {
          if (customer.customer_details) {
            // console.log('Nested Customer Details:', customer);
            this.setState({ customer_details: customer.customer_details });
          }
        });
        // console.log(this.state.patients);

        // Update state with patient info
        this.setState({
          af: data.af ? "yes" : "no",
          duration: data.exp_device_use_days,
          cardiac_surgery: data.cardiac_surgery ? "yes" : "no",
          thyroid_function: data.thyroid,
          diabetes: data.diabetes,
          indication: data.indication,
          height: data.height,
          weight: data.weight,
          hospital: data.hospital,
          phone: data.phone,
          patient_name: data.name,
          email_id: data.email,
          selectedDoctor: { value: data.doctor_id, label: data.doctor_name },
          macid: dataid2,
          priority: dataid3,
          dob: data.dob,
          duration: data.exp_device_use_days,
          filetype: data.fileType,
        });

        // Handle DOB formatting
        const dob = new Date(Date.parse(data.dob));
        const formattedDob = `${
          dob.getMonth() + 1
        }/${dob.getDate()}/${dob.getFullYear()}`;
        this.setState({ dob: formattedDob });

        // Second API call for verifiers
        return axios.get(`${apiUrl}api/users/allverifiers`);
      })
      .then((res) => {
        const verifiers = res.data;
        const formattedVerifiers = verifiers.map((verifier) => ({
          value: verifier._id,
          label: verifier.name,
          patients: verifier.patients, // Assuming patients is an array of user IDs
        }));

        const filteredVerifiers = formattedVerifiers.filter((verifier) =>
          verifier.patients.includes(userid)
        );
        // console.log("filtered verifier!!!!!", filteredVerifiers);

        // console.log('Filtered verifiers with matching user:', this.state.priority);

        this.setState({ selectedVerifiers: filteredVerifiers });

        this.setState({ verifiers: formattedVerifiers });
        // console.log('formatted verifiers:',verifiers)
        // Third API call for patient search (ipop)
        this.state.patients.forEach((patient) => {
          if (patient._id === userid) {
            this.setState({ comments: patient.comments });
            // this.setState({ priority: patient.priority });
            this.setState({ ipop: patient.ip_op });
          }
        });

        // console.log("ipop", this.state.patients);

        // Fourth API call for verified percentage
        return axios.get(`${apiUrl}api/users/getVerifiedPercentage/${userid}`);
      })
      .then((res) => {
        const ecgValues = res.data.ecgValues; // Assuming `ecgValues` is an array
        const selectedVerifiers = this.state.selectedVerifiers;
        const fileType = this.state.filetype || "easy"; // 'easy', 'medium', 'hard'

        const allowedTimePerECGInMinutes =
          fileType === "easy" ? 4 : fileType === "medium" ? 6 : 9;

        const verifierEfficiencyResults = [];

        if (selectedVerifiers && selectedVerifiers.length > 0) {
          selectedVerifiers.forEach((verifier) => {
            const filteredECGValues = ecgValues
              .filter((ecg) => ecg.verifierId === verifier.value)
              .sort((a, b) => new Date(a.verifyTime) - new Date(b.verifyTime));

            if (filteredECGValues.length > 0) {
              // Here you can extract any data you need from `filteredECGValues` and pass it as a parameter
              // Assuming we are passing the `filteredECGValues` directly or extracting an ID or relevant data for `databaseid`
              const databaseId = filteredECGValues
                .map((ecg) => ecg.ecgvalue_id)
                .join(","); // Assuming `id` is the relevant field

              axios
                .post(
                  `${apiUrl}api/common/getTotalStripCount?userid=${userid}&databaseid=${databaseId}`
                )
                .then((apiResponse) => {
                  const apiData = apiResponse.data;

                  const totalMinutes = apiData; // Example input

                  const hours = Math.floor(totalMinutes / 60); // Get the number of hours
                  const minutes = totalMinutes % 60; // Get the remaining minutes

                  const formattedTime = `${hours}Hrs:${minutes
                    .toString()
                    .padStart(2, "0")}Mins`; // Format as hrs:mins
                  console.log("Formatted time (hours:min):", formattedTime);

                  const startTime = new Date(filteredECGValues[0].verifyTime);
                  const endTime = new Date(
                    filteredECGValues[filteredECGValues.length - 1].verifyTime
                  );

                  let totalEfficientCount = 0;
                  let totalComparisons = 0;
                  let totalTimeSpentInMinutes = 0;

                  filteredECGValues.forEach((ecg, index) => {
                    if (index > 0) {
                      const prevVerifyTime = new Date(
                        filteredECGValues[index - 1].verifyTime
                      );
                      const currentVerifyTime = new Date(ecg.verifyTime);
                      const timeDifferenceInMinutes =
                        (currentVerifyTime - prevVerifyTime) / (1000 * 60);

                      totalTimeSpentInMinutes += timeDifferenceInMinutes;

                      if (
                        timeDifferenceInMinutes <= allowedTimePerECGInMinutes
                      ) {
                        totalEfficientCount++;
                      }

                      totalComparisons++;
                    }
                  });

                  const efficiency =
                    totalComparisons > 0
                      ? (totalEfficientCount / totalComparisons) * 100
                      : 0;
                  const averageTimePerECG =
                    totalComparisons > 0
                      ? totalTimeSpentInMinutes / totalComparisons
                      : 0;
                  const remainingECGCount =
                    ecgValues.length - filteredECGValues.length;
                  const estimatedRemainingTime =
                    averageTimePerECG * remainingECGCount;

                  verifierEfficiencyResults.push({
                    label: verifier.value,
                    value: efficiency.toFixed(2),
                    averageTimePerECG: averageTimePerECG.toFixed(2),
                    estimatedRemainingTime: estimatedRemainingTime.toFixed(2),
                    startTime: startTime.toISOString(),
                    endTime: endTime.toISOString(),
                    totalTimeSpentInMinutes: totalTimeSpentInMinutes || "N/A",
                    totalSpendHours: formattedTime || "N/A",
                  });

                  this.setState({
                    selectedVerifiersEfficency: verifierEfficiencyResults,
                  });
                })
                .catch((err) => {
                  console.error("Error fetching data from API", err);
                });
            } else {
              console.log(
                `No ECG values found for Verifier ID: ${verifier.value}`
              );
            }
          });
        } else {
          console.log("No selected verifiers found.");
        }

        const totalLength = ecgValues.length;
        let verifyCount = 0;

        ecgValues.forEach((value) => {
          if (value.verifyStatus === 1) {
            verifyCount++;
          }
        });

        if (totalLength > 0) {
          const percentageVerified = (verifyCount / totalLength) * 100;
          this.setState({ percentageVerified: percentageVerified.toFixed(2) });
        }
      })

      .catch((error) => {
        console.error("Error fetching ECG data:", error);
      });
  }
  getConfirmTime(e) {
    console.log(e.currentTarget.id);
    const userid = e.currentTarget.id;
    const now = new Date();
    const updatedDate = `${now.getFullYear()}-${String(
      now.getMonth() + 1
    ).padStart(2, "0")}-${String(now.getDate()).padStart(2, "0")} ${String(
      now.getHours()
    ).padStart(2, "0")}:${String(now.getMinutes()).padStart(2, "0")}:${String(
      now.getSeconds()
    ).padStart(2, "0")}`;

    // console.log("Clicked date and time:", updatedDate);

    // Make the API call
    axios
      .post(`${apiUrl}api/patients/updateUpdateDate`, {
        userid,
        updatedDate,
      })
      .then((response) => {
        // console.log(response);

        // Set showButton to false to hide the button after successful update
        this.setState({ showButton: false }, () => {
          // console.log("Button hidden:", this.state.showButton); // Ensure the state is updated
        });
        this.setState((prevState) => ({
          refreshKey: prevState.refreshKey + 1,
        }));
        this.fetchData();
      })
      .catch((error) => {
        // console.error("Error updating date:", error);
      });
  }

  selectItem(e) {
    var userid = e.currentTarget.id;
    this.setState({ userid: userid });
    this.setState({
      nor_minhr: "",
      nor_maxhr: "",
      qtcformula: "",
      qtcvalue_min: "",
      qtcvaluemax: "",
      qrswidth_min: "",
      qrswidth_max: "",
      qrsamp_min: "",
      qrsamp_max: "",
      stelevation: "",
      pwidth_min: "",
      pwidth_max: "",
      pamp_min: "",
      pamp_max: "",
      stdepression: "",
      twidth_min: "",
      twidth_max: "",
      tamp_min: "",
      tamp_max: "",
      printerval_min: "",
      printerval_max: "",
      tachyhr_lower: "",
      tachyhr_upper: "",
      bradyhr_lower: "",
      bradyhr_upper: "",
      pauselength: "",
      graph_gen: "",
    });
    axios
      .get(apiUrl + "api/patients/settings?userid=" + userid)
      .then((res) => {
        var data = res.data;
        this.setState(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  handleSearchChange = (e) => {
    this.setState({ searchTerm: e.target.value });
  };
  resetModalReport() {
    // console.log("resetmodal clicked!!!");
    this.setState({ isModalReportOpen: false });
    this.fetchData();
  }
  resetInfoModal() {
    this.setState({
      smoking: "",
      diabetes: "",
      af: "",
      hyper_tension: "",
      hyper_lipidemia: "",
      indication: "",
      cardiac_surgery: "",
      thyroid_function: "",
      customer_details: "", // Reset customer details too
      reportstatus: "",
      dob: "",
      height: "",
      weight: "",
      hospital: "",
      phone: "",
      patient_name: "",
      email_id: "",
      doctor_name: "",
      doctor_id: "",
      duration: "",
      selectedVerifiers: [],
      verifiers: [],
      comments: "",
      priority: "",
      ipop: "",
      percentageVerified: "",
      macid: "",
      filetype: "",
      hourstocompleted: "",
    });
    // console.log(this.state.verifiers);
  }

  render() {
    if (localStorage.jwtToken == undefined) {
      window.location.href = "/";
    }
    const { errors, doctors, selectedDoctor, dob } = this.state;
    const options = doctors.map((doctor) => ({
      value: doctor._id,
      label: doctor.name,
    }));

    /* const { errors,doctors } = this.state;*/

    // Map filtered doctors to options
    // const doctorsList =
    //   filteredDoctors.length > 0
    //     ? filteredDoctors.map((item, i) => (
    //         <option
    //           key={i}
    //           value={item._id}
    //           selected={doctor_name === item.name}
    //         >
    //           {item.name}
    //         </option>
    //       ))
    //     : null;
    return (
      <div className="wrapper theme-6-active box-layout pimary-color-green">
        <TopNav profileLink={this.state.profileLink} />
        <AddModalReport
          userid={this.state.generatereportuserid}
          onSelectModalReport={this.resetModalReport.bind(this)}
          isOpen={this.state.isModalReportOpen}
          treemenu={this.state.treemenu}
        />
        <AddModalComplete
          userid={this.state.userid}
          isOpen={this.state.isModalCompleteOpen}
        />
        <SideBar menus={this.state.menus} />

        <div className="right-sidebar-backdrop"></div>
        <div className="page-wrapper" style={{ marginTop: "30px" }}>
          <div className="container-fluid">
            <div id="myModalDHome" className="modal fade" role="dialog">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                    >
                      &times;
                    </button>
                    <h4 className="modal-title text-center">
                      General Settings
                    </h4>
                  </div>
                  <form method="post" onSubmit={this.onSubmit}>
                    <div className="modal-body">
                      <div className="row row-sm-offset">
                        <br />
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Normal Heart Rate Min
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.nor_minhr,
                              })}
                              name="nor_minhr"
                              required=""
                              id="nor_minhr"
                              onChange={this.onChange}
                              value={this.state.nor_minhr}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Normal Heart Rate Max
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.nor_maxhr,
                              })}
                              name="nor_maxhr"
                              required=""
                              id="nor_maxhr"
                              onChange={this.onChange}
                              value={this.state.nor_maxhr}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              QTC Formula
                            </label>
                            <select
                              name="qtcformula"
                              className={classnames("form-control", {
                                errors: errors.qtcformula,
                              })}
                              value={this.state.qtcformula}
                              onChange={this.onChange}
                            >
                              <option value="Bazett">Bazett </option>
                              <option value="Fridericia">Fridericia</option>
                              <option value="Framingham">Framingham</option>
                              <option value="Hodges">Hodges</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <br />
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              QTC Value Min
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.qtcvalue_min,
                              })}
                              name="qtcvalue_min"
                              required=""
                              id="qtcvalue_min"
                              onChange={this.onChange}
                              value={this.state.qtcvalue_min}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              QTC Value Max
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.qtcvaluemax,
                              })}
                              name="qtcvaluemax"
                              required=""
                              id="qtcvaluemax"
                              onChange={this.onChange}
                              value={this.state.qtcvaluemax}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              QRS Width Min(ms){" "}
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.qrswidth_min,
                              })}
                              name="qrswidth_min"
                              required=""
                              id="qrswidth_min"
                              onChange={this.onChange}
                              value={this.state.qrswidth_min}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              QRS Width Max (ms){" "}
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.qrswidth_max,
                              })}
                              name="qrswidth_max"
                              required=""
                              id="qrswidth_max"
                              onChange={this.onChange}
                              value={this.state.qrswidth_max}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              QRS Amplitude Min (mv)
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.qrsamp_min,
                              })}
                              name="qrsamp_min"
                              required=""
                              id="qrsamp_min"
                              onChange={this.onChange}
                              value={this.state.qrsamp_min}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              QRS Amplitude Max (mv)
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.qrsamp_max,
                              })}
                              name="qrsamp_max"
                              required=""
                              id="qrsamp_max"
                              onChange={this.onChange}
                              value={this.state.qrsamp_max}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              ST Elevation (mm)
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.stelevation,
                              })}
                              name="stelevation"
                              required=""
                              id="stelevation"
                              onChange={this.onChange}
                              value={this.state.stelevation}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              P Width Min (ms)
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.pwidth_min,
                              })}
                              name="pwidth_min"
                              required=""
                              id="pwidth_min"
                              onChange={this.onChange}
                              value={this.state.pwidth_min}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              P Width Max (ms)
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.pwidth_max,
                              })}
                              name="pwidth_max"
                              required=""
                              id="pwidth_max"
                              onChange={this.onChange}
                              value={this.state.pwidth_max}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              P Amplitude Min (mv)
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.pamp_min,
                              })}
                              name="pamp_min"
                              required=""
                              id="pamp_min"
                              onChange={this.onChange}
                              value={this.state.pamp_min}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              P Amplitude Max (mv)
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.pamp_max,
                              })}
                              name="pamp_max"
                              required=""
                              id="pamp_max"
                              onChange={this.onChange}
                              value={this.state.pamp_max}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              T Width Min (ms){" "}
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.twidth_min,
                              })}
                              name="twidth_min"
                              required=""
                              id="twidth_min"
                              onChange={this.onChange}
                              value={this.state.twidth_min}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              T Width Max (ms)
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.twidth_max,
                              })}
                              name="twidth_max"
                              required=""
                              id="twidth_max"
                              onChange={this.onChange}
                              value={this.state.twidth_max}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              T Amplitude Min (mv)
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.tamp_min,
                              })}
                              name="tamp_min"
                              required=""
                              id="tamp_min"
                              onChange={this.onChange}
                              value={this.state.tamp_min}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              T Amplitude Max (mv)
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.tamp_max,
                              })}
                              name="tamp_max"
                              required=""
                              id="tamp_max"
                              onChange={this.onChange}
                              value={this.state.tamp_max}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              PR Interval Min{" "}
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.printerval_min,
                              })}
                              name="printerval_min"
                              required=""
                              id="printerval_min"
                              onChange={this.onChange}
                              value={this.state.printerval_min}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              PR Interval Max{" "}
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.printerval_max,
                              })}
                              name="printerval_max"
                              required=""
                              id="printerval_max"
                              onChange={this.onChange}
                              value={this.state.printerval_max}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Tachy Lower Limit{" "}
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.tachyhr_lower,
                              })}
                              name="tachyhr_lower"
                              required=""
                              id="tachyhr_lower"
                              onChange={this.onChange}
                              value={this.state.tachyhr_lower}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Tachy Upper Limit{" "}
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.tachyhr_upper,
                              })}
                              name="tachyhr_upper"
                              required=""
                              id="tachyhr_upper"
                              onChange={this.onChange}
                              value={this.state.tachyhr_upper}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Brady Lower Limit{" "}
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.bradyhr_lower,
                              })}
                              name="bradyhr_lower"
                              required=""
                              id="bradyhr_lower"
                              onChange={this.onChange}
                              value={this.state.bradyhr_lower}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Brady Upper Limit{" "}
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.bradyhr_upper,
                              })}
                              name="bradyhr_upper"
                              required=""
                              id="bradyhr_upper"
                              onChange={this.onChange}
                              value={this.state.bradyhr_upper}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              ST Depression (mm)
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.stdepression,
                              })}
                              name="stdepression"
                              required=""
                              id="stdepression"
                              onChange={this.onChange}
                              value={this.state.stdepression}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Pause Length (s){" "}
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.pauselength,
                              })}
                              name="pauselength"
                              required=""
                              id="pauselength"
                              onChange={this.onChange}
                              value={this.state.pauselength}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Graphs To Be Generated(no:){" "}
                            </label>
                            <input
                              type="text"
                              className={classnames("form-control", {
                                errors: errors.graph_gen,
                              })}
                              name="graph_gen"
                              required=""
                              id="graph_gen"
                              onChange={this.onChange}
                              value={this.state.graph_gen}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="submit"
                        className="btn btn-success"
                        style={{ color: "Black" }}
                      >
                        Submit
                      </button>
                      {
                        <button
                          id="closePopup"
                          type="button"
                          className="btn btn-default closePopup"
                          data-dismiss="modal"
                          style={{ color: "Black" }}
                        >
                          Close
                        </button>
                      }
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div
              id="commentinfomodal"
              className="modal fade"
              role="dialog"
              aria-labelledby="commentModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <h4
                      className="modal-title text-center"
                      id="commentModalLabel"
                    >
                      Add comments
                    </h4>
                  </div>

                  <div className="modal-body">
                    <textarea
                      value={this.state.comments || ""} // Default to empty string if comments is undefined
                      style={{ width: "100%", height: "100px", color: "black" }}
                      placeholder="Enter your comments here"
                      onChange={(e) =>
                        this.setState({ comments: e.target.value })
                      } // Update comments directly in state
                    />
                  </div>
                  <div className="modal-footer">
                    <div className="row row-sm-offset">
                      <div className="col-md-6 multi-horizontal">
                        <button
                          id={this.state.userid}
                          type="submit"
                          className="btn btn-success"
                          style={{ color: "Black" }}
                          onClick={this.submitComment}
                        >
                          Submit
                        </button>
                      </div>
                      <div className="col-md-6 multi-horizontal text-left">
                        <button
                          type="button"
                          className="btn btn-default closePopup"
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {this.state.verifiertype === "8" && (
              <div
                id="progressinfomodal"
                className="modal fade"
                role="dialog"
                aria-labelledby="commentModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <button
                        onClick={this.resetInfoModal.bind(this)}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                      <h4
                        className="modal-title text-center"
                        id="commentModalLabel"
                      >
                        Verifier Progress
                      </h4>
                    </div>

                    <div className="modal-body">
                      {this.state.selectedVerifiers &&
                        this.state.selectedVerifiers.map((verifier, index) => {
                          // Find matching efficiency data, ensure selectedVerifiersEfficency is defined
                          const efficiencyData =
                            (this.state.selectedVerifiersEfficency &&
                              this.state.selectedVerifiersEfficency.find(
                                (eff) => eff.label === verifier.value
                              )) ||
                            {}; // Fallback to empty object if not found

                          // Extract efficiency value and average time per ECG
                          const efficiencyValue = efficiencyData.value || 0;
                          const averageTimePerECG =
                            efficiencyData.averageTimePerECG || "N/A";
                          const hourstocompleted =
                            efficiencyData.hourstocompleted;

                          // Function to format date and time
                          const formatDateTime = (isoDateTime) => {
                            if (
                              !isoDateTime ||
                              isNaN(Date.parse(isoDateTime))
                            ) {
                              return "N/A"; // Return "N/A" if input is invalid
                            }

                            // Convert ISO string to Date object
                            const date = new Date(isoDateTime);

                            // Adjust time for IST (+5:30)
                            date.setMinutes(date.getMinutes());

                            // Format the date and time components
                            const formattedDate =
                              date.toLocaleDateString("en-IN"); // Indian locale
                            const formattedTime = date.toLocaleTimeString(
                              "en-IN",
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: true, // Use 12-hour format with AM/PM
                              }
                            );

                            return `${formattedDate || "NA"} ${
                              formattedTime || "NA"
                            }`;
                          };

                          // Format start time and end time
                          const startTime =
                            formatDateTime(efficiencyData.startTime) || "N/A";
                          const endTime =
                            formatDateTime(efficiencyData.endTime) || "N/A";

                          // Determine progress bar color based on efficiency value
                          let progressColor = "low-efficency"; // Default to red for 0-40
                          if (efficiencyValue >= 81 && efficiencyValue <= 100) {
                            progressColor = "high-efficency"; // Green for 81-100
                          } else if (
                            efficiencyValue >= 71 &&
                            efficiencyValue <= 80
                          ) {
                            progressColor = "average-efficency"; // Light Green for 71-80
                          } else if (
                            efficiencyValue >= 41 &&
                            efficiencyValue <= 70
                          ) {
                            progressColor = "medium-efficency"; // Yellow for 41-70
                          }

                          return (
                            <div
                              key={index}
                              className="d-flex align-items-center mb-3"
                            >
                              <div
                                className="flex-grow-1"
                                style={{ color: "black" }}
                              >
                                {verifier.label}: {startTime} - {endTime}
                              </div>
                              <div
                                className="flex-grow-1"
                                style={{ color: "black" }}
                              >
                                Total Time Analaysed:
                                {efficiencyData.totalSpendHours}
                              </div>
                              <div
                                className="progress"
                                style={{ width: "60%", height: "17px" }}
                              >
                                <div
                                  className={`progress-bar ${progressColor}`}
                                  role="progressbar"
                                  style={{
                                    width: `${efficiencyValue}%`,
                                  }}
                                  aria-valuenow={efficiencyValue}
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >
                                  <p
                                    style={{
                                      fontSize: "12px",
                                      marginTop: "-2%",
                                    }}
                                  >
                                    {efficiencyValue}% - {averageTimePerECG} Min
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>

                    <div className="modal-footer">
                      <div>
                        <p style={{ color: "black", display: "flex" }}>
                          Time to Deliver Report: {this.state.hourstocompleted}
                        </p>
                      </div>
                      <div className="row row-sm-offset">
                        <div className="col-md-6 multi-horizontal"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div
              id="editInfoModal"
              className="modal fade"
              role="dialog"
              data-backdrop="static" // Prevents closing on outside click
              data-keyboard="false"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      onClick={this.resetInfoModal.bind(this)}
                    >
                      &times;
                    </button>
                    <h4 className="modal-title text-center">
                      Edit Patient Information
                    </h4>
                  </div>
                  <form method="post" onSubmit={this.onSubmitInfo}>
                    <div className="modal-body">
                      <div className="row row-sm-offset">
                        <br />
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="patient_name"
                              onChange={this.onChange}
                              value={this.state.patient_name}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Email
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="email_id"
                              onChange={this.onChange}
                              value={this.state.email_id}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <br />
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Doctor Name
                            </label>

                            <Select
                              options={options} // Provide the options
                              value={selectedDoctor} // Controlled component value
                              onChange={this.handleDoctorChange} // Handle selection
                              placeholder="Search Doctor"
                              classNamePrefix="react-select"
                              styles={{
                                control: (base) => ({
                                  ...base,
                                  borderColor: errors.doctor_id
                                    ? "red"
                                    : base.borderColor,
                                }),
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              DOB
                            </label>
                            <div className="input-group">
                              <Datetime
                                value={dob}
                                onChange={this.handleDateChange}
                                inputProps={{
                                  className: `form-control ${
                                    errors.dob ? "is-invalid" : ""
                                  }`,
                                  placeholder: "Select Date",
                                }}
                                dateFormat="MM/DD/YYYY"
                                timeFormat={false}
                              />
                              <span className="input-group-addon">
                                <span className="fa fa-calendar"></span>
                              </span>
                            </div>
                            {errors.dob && (
                              <div className="invalid-feedback">
                                {errors.dob}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <br />
                        <div className="col-md-6 multi-horizontal">
                          {/* <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Height
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="height"
                              onChange={this.onChange}
                              value={this.state.height}
                            />
                          </div> */}
                        </div>
                        <div className="col-md-6 multi-horizontal">
                          {/* <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Weight
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="weight"
                              onChange={this.onChange}
                              value={this.state.weight}
                            />
                          </div> */}
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <br />
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Hospital Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="hospital"
                              onChange={this.onChange}
                              value={this.state.hospital}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Phone
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="phone"
                              onChange={this.onChange}
                              value={this.state.phone}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row row-sm-offset">
                        <br />
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Assign Verifier
                            </label>

                            <Select
                              isMulti
                              name="Assign Verifier"
                              options={this.state.verifiers} // Pass the verifier options
                              value={this.state.selectedVerifiers} // Set the selected verifiers
                              onChange={this.handleVerifierChange}
                              // Update the selected verifiers on change
                              className="basic-multi-select"
                              classNamePrefix="select"
                              placeholder="Select verifiers..."
                              styles={{
                                option: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: state.isSelected
                                    ? "blue"
                                    : state.isFocused
                                    ? "#e5e5e5"
                                    : null,
                                  color: state.isSelected ? "white" : "black",
                                }),
                                multiValue: (provided) => ({
                                  ...provided,
                                  backgroundColor: "blue",
                                }),
                                multiValueLabel: (provided) => ({
                                  ...provided,
                                  color: "white",
                                }),
                                multiValue: (provided, state) => ({
                                  ...provided,
                                  key: state.data.value, // Use the unique value as the key
                                  backgroundColor: "blue",
                                }),
                              }}
                            />

                            {/* Display selected verifier names below the select element */}
                            {this.state.selectedVerifiers.length > 0 && (
                              <div style={{ marginTop: "10px" }}>
                                <strong>Selected Verifiers:</strong>{" "}
                                {this.state.selectedVerifiers
                                  .map((verifier) => verifier.label)
                                  .join(", ")}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Priority
                            </label>
                            <select
                              name="Priority"
                              className="form-control"
                              value={this.state.priority}
                              onChange={this.onPriorityChange}
                            >
                              <option value="4">NA</option>

                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <br />
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Atrial Fibrillation
                            </label>
                            <select
                              name="smoking"
                              className="form-control"
                              value={this.state.af}
                              onChange={this.onChange}
                            >
                              <option value="">Choose </option>
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Report Status
                            </label>
                            <select
                              name="Report Status"
                              className="form-control"
                              value={this.state.reportstatus}
                              onChange={this.onReportStatuschange}
                            >
                              <option value="0">Not Analayzed </option>

                              <option value="2">Analyze Compeleted </option>
                              <option value="4">In Preparation</option>
                              <option value="5">Report Completed</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <br />
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              IP/OP
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="patient_name"
                              onChange={this.onIpOpChange}
                              value={this.state.ipop}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Indication
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="indication"
                              onChange={this.onChange}
                              value={this.state.indication}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row row-sm-offset">
                        <br />
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              monitoring duration
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="patient_name"
                              onChange={this.onDurationChange}
                              value={this.state.duration}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Gender
                            </label>
                            <select
                              name="Gender"
                              className="form-control"
                              onChange={this.onGenderChange}
                              value={this.state.gender}
                            >
                              <option value="M">M</option>

                              <option value="F">F</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="row row-sm-offset">
                        <br />
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Thyroid Functions
                            </label>
                            <select
                              name="thyroid_function"
                              className="form-control"
                              value={this.state.thyroid_function}
                              onChange={this.onChange}
                            >
                              <option value="Normal">Normal</option>
                              <option value="Hyper">Hyper</option>
                              <option value="Hypo">Hypo</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Cardiac Surgery/Interventions
                            </label>
                            <select
                              name="cardiac_surgery"
                              className="form-control"
                              value={this.state.cardiac_surgery}
                              onChange={this.onChange}
                            >
                              <option value="yes">Yes</option>
                              <option value="no">NO</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              Verified percentage
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="verified_percentage"
                              onChange={this.onChange}
                              value={this.state.percentageVerified}
                              disabled="true"
                            />
                          </div>
                        </div>
                        <div className="col-md-6 multi-horizontal">
                          <div className="form-group">
                            <label
                              className="form-control-label mbr-fonts-style display-7"
                              style={{ color: "Black" }}
                            >
                              File Type
                            </label>
                            <select
                              name="cardiac_surgery"
                              className="form-control"
                              value={this.state.filetype}
                              onChange={this.handleTypeChange}
                            >
                              <option value="easy">Easy</option>
                              <option value="moderate">Moderate</option>
                              <option value="hard">Hard</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <div className="row row-sm-offset">
                        <div className="col-md-6 multi-horizontal">
                          <button
                            type="submit"
                            className="btn btn-success"
                            style={{ color: "Black" }}
                          >
                            Submit
                          </button>
                        </div>
                        {
                          <div className="col-md-6 multi-horizontal text-left">
                            <button
                              id=""
                              type="button"
                              className="btn btn-default closePopup"
                              data-dismiss="modal"
                            >
                              Close
                            </button>
                          </div>
                        }
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="panel panel-default card-view">
                  <div className="panel-heading">
                    <div className="pull-left">
                      <h6 className="panel-title txt-dark">Patient List</h6>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                  <div className="panel-wrapper collapse in">
                    <div className="panel-body">
                      <MDBDataTable
                        id="mbdTable"
                        noBottomColumns
                        data={this.state.data}
                        entries={100} // Set default number of entries to 100
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PatientList;
