import React from 'react';
import { Link } from 'react-router-dom';
// import './NotFound.css';  // Optional: for styling the 404 page
import './Error.css'
const AdminHome='admin-home'
const verifiertype=localStorage.getItem('verifiertype')
const NotFound = () => {
    return (
    <div className="notfound-container">
      <h1>404</h1>
      <h2>Page Not Found</h2>
      <p>Oops! The page you're looking for doesn't exist.</p>
      <Link
  to={verifiertype === '2' ? '/patient-list' : verifiertype === '3' ? '/doctor-home' : '/login'}
  className="back-home-link"
>
  Go Back Home
</Link>

    </div>
  );
}

export default NotFound;
